import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLeaves,
  applyLeave,
  deleteLeave,
  editLeave,
  selectUserLeaves,
  selectLeavesLoading,
  selectLeavesCount,
} from "../../redux/features/leaveSlice";
import { toast } from "react-toastify";
import { IoPencil, IoCloseOutline, IoEyeOutline } from "react-icons/io5";
import { useStateContext } from "../../components/contexts/ContextProvider";
import moment from "moment";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { leaveSchema } from "../../validation-schemas/schemas";
import { EditModal, DeleteModal, DetailModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";

const EmpLeaves = () => {
  const dispatch = useDispatch();
  const leaves = useSelector(selectUserLeaves);
  const leaveLoading = useSelector(selectLeavesLoading);
  const count = useSelector(selectLeavesCount);
  const [deleteId, setDeleteId] = useState("");

  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    openDetailModal,
    closeDetailModal,
    isToday,
    activeProfile,
  } = useStateContext();

  const newDate = new Date();
  const newRequestDate = newDate.toISOString();
  const formattedDate = moment(newDate).format("YYYY-MM-DD");

  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Apply Leave");
  const [isReason, setIsReason] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const initialValues = {
    employee: activeProfile?.id,
    leave_type: "",
    number_of_days: "1",
    reason: "",
    request_date: newRequestDate,
    from_date: formattedDate,
    to_date: formattedDate,
    uidb64: "",
  };
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    // Convert number_of_days to integer
    const days = parseInt(formValues.number_of_days);

    if (formValues.from_date && days > 0) {
      const toDate = new Date(formValues.from_date);
      toDate.setDate(toDate.getDate() + days - 1); // Add days to the from_date
      setFormValues((prevValues) => ({
        ...prevValues,
        to_date: toDate.toISOString().split("T")[0], // Format date as YYYY-MM-DD
      }));
    }
  }, [formValues.from_date, formValues.number_of_days]);

  const rowChangeUseEffect = () => {
    if (leaves.length < count) {
      dispatch(
        getLeaves({
          toast,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      leaves.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getLeaves({
          toast,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (leaves.length < page * perPage &&
          (!leaves.length || leaves.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getLeaves({
            toast,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const handleButtonClick = (row) => {
    if (row.status !== "PENDING") {
      toast.error("Sorry, You can Edit Pending Leaves");
      return;
    }

    handleEdit(row);
    setDeleteId(row.id);
  };

  const columns = [
    {
      name: (
        <div className="text-center w-full text-slate-600">Request Type</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {snakeToTitleCase(row.leave_type)}
        </div>
      ),
      selector: (row) => row.leave_type,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Reason</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setIsReason(row.reason);

              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Request Date</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.request_date)
            ? "Today"
            : moment(row.request_date).format("MMM. DD, YYYY")}
        </div>
      ),
      selector: (row) => row.request_date,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">From Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.from_date)
            ? "Today"
            : moment(row.from_date).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">To Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.to_date)
            ? "Today"
            : moment(row.to_date).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">No. of Days</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.number_of_days}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.status === "REJECTED"
                ? "bg-red-600"
                : row.status === "APPROVED"
                ? "bg-emerald-600"
                : "bg-sky-600"
            }`}
          >
            {snakeToTitleCase(row.status)}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Approved / Rejected By
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.approved_by ? row.approved_by.approved_by_name : null}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className={`cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 ${
                row.status !== "PENDING" ? "cursor-not-allowed" : ""
              }`}
              onClick={() => {
                handleButtonClick(row);
              }}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Request");
    openEditModal();
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Apply Request");
    openEditModal();
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "number_of_days") {
      if (value < 0 || value === "0") {
        setFormValues({ ...formValues, [name]: 1 });
      } else {
        setFormValues({ ...formValues, [name]: value });
      }
    } else if (name === "from_date") {
      // Calculate the difference in days between From Date and To Date
      const fromDate = moment(value);
      const toDate = fromDate
        .clone()
        .add(formValues.number_of_days - 1, "days");

      setFormValues({
        ...formValues,
        from_date: value,
        to_date: toDate.format("YYYY-MM-DD"),
      });
    } else if (name === "to_date") {
      // Calculate the difference in days between From Date and To Date
      const fromDate = moment(formValues.from_date);
      const toDate = moment(value);
      const numberOfDays = toDate.diff(fromDate, "days") + 1;

      setFormValues({
        ...formValues,
        [name]: value,
        number_of_days: numberOfDays.toString(),
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  function checkLeaveValidity(newFromDate, newToDate, rowIdToExclude) {
    // Iterate through the user's leave history
    for (const leaveRecord of leaves) {
      if (leaveRecord.id === rowIdToExclude) {
        continue;
      }
      const existingFromDate = leaveRecord.from_date;
      const existingToDate = leaveRecord.to_date;

      // Check for overlap
      if (
        checkOverlap(existingFromDate, existingToDate, newFromDate, newToDate)
      ) {
        // Overlapping dates found, leave is not valid
        return false;
      }
    }

    // No overlapping dates found, leave is valid
    return true;
  }

  function checkOverlap(date1Start, date1End, date2Start, date2End) {
    return date1Start <= date2End && date1End >= date2Start;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await leaveSchema.validate(formValues, { abortEarly: false });
      try {
        const { uidb64, from_date, to_date, request_date, leave_type, reason } =
          formValues;
        const isLeaveValid = checkLeaveValidity(from_date, to_date, uidb64);
        if (isLeaveValid) {
          if (uidb64 === "" || uidb64 === null || uidb64 === undefined) {
            dispatch(
              applyLeave({ formValues, toast, setEnable, closeEditModal })
            );
          } else {
            const updatedValues = {
              from_date,
              to_date,
              reason,
              leave_type,
              request_date,
            };
            dispatch(
              editLeave({
                uidb64,
                formValues: updatedValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          }
        } else {
          toast.error("Leave dates overlap with existing leaves");
        }
      } catch (err) {
        console.error("Failed to add leave", err);
      }
    } catch (validationErrors) {
      const errors = {};
      if (validationErrors) {
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
      }
      setErrors(errors);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteLeave({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  return (
    <div>
      {leaveLoading ? (
        <div className="dashboard-min-height grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <div>
          <DetailModal>
            <div className="min-w-[400px] min-h-[200px] bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600 text-lg">
                  Reason:
                </h5>
                <IoCloseOutline
                  className="w-8 h-8 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={closeDetailModal}
                />
              </div>
              <div className="p-4">
                <p className="text-slate-500">{isReason}</p>
              </div>
            </div>
          </DetailModal>
          <EditModal>
            <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
                <IoCloseOutline
                  className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={closeEditModal}
                />
              </div>
              <div className="m-1">
                <form onSubmit={handleSubmit}>
                  <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Request Type
                      </label>
                      <select
                        value={formValues.leave_type}
                        className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        onChange={handleChange}
                        type="text"
                        name="leave_type"
                      >
                        <option value="">Select Type</option>
                        <option value="SICK_LEAVE">Sick Leave</option>
                        <option value="CASUAL_LEAVE">Casual Leave</option>
                        <option value="EMERGENCY_LEAVE">Emergency Leave</option>
                        <option value="MATERNITY_LEAVE">Maternity Leave</option>
                        <option value="PATERNITY_LEAVE">Paternity Leave</option>
                        <option value="MARRIAGE_LEAVE">Marriage Leave</option>
                        <option value="WORK_FROM_HOME">Work From Home</option>
                        <option value="EXTRA_DAYS">Extra Days</option>
                      </select>
                      {errors.leave_type && (
                        <p className="form-errors">{errors.leave_type}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        No. of Days
                      </label>
                      <input
                        value={formValues.number_of_days}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="number"
                        min="1"
                        onChange={handleChange}
                        name="number_of_days"
                      />
                      {errors.number_of_days && (
                        <p className="form-errors">{errors.number_of_days}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">From</label>
                      <input
                        value={formValues.from_date}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="date"
                        onChange={handleChange}
                        name="from_date"
                      />
                      {errors.from_date && (
                        <p className="form-errors">{errors.from_date}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">To</label>
                      <input
                        value={formValues.to_date}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="date"
                        onChange={handleChange}
                        name="to_date"
                        min={formValues.from_date}
                      />
                      {errors.to_date && (
                        <p className="form-errors">{errors.to_date}</p>
                      )}
                    </div>

                    <div className="md:col-span-2">
                      <label className="ml-1 mb-1 text-slate-600">Reason</label>
                      <textarea
                        value={formValues.reason}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md resize-none"
                        onChange={handleChange}
                        name="reason"
                        rows={10}
                      />
                      {errors.reason && (
                        <p className="form-errors">{errors.reason}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                    <div className="flex-grow flex justify-between items-center">
                      <div>
                        <button
                          type="button"
                          className={`px-3 py-2 bg-red-500/70 hover:bg-red-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300 ${
                            formValues.status === "APPROVED" ||
                            formValues.status === "REJECTED"
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (formValues.status === "PENDING") {
                              closeEditModal();
                              openDeleteModal();
                            }
                          }}
                          disabled={
                            formValues.status === "APPROVED" ||
                            formValues.status === "REJECTED"
                          }
                        >
                          Delete
                        </button>
                      </div>
                      <div className="flex gap-4 justify-end">
                        {enable ? (
                          <button
                            className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                            type="submit"
                          >
                            Save
                          </button>
                        ) : (
                          <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                            <RotatingLinesLoader />
                          </div>
                        )}
                        <button
                          type="button"
                          className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                          onClick={closeEditModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </EditModal>
          <DeleteModal handleDelete={handleDelete} />

          <div className="flex flex-col justify-between lg:flex-row items-center mx-3 rounded-md">
            <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full rounded-md">
              <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between rounded-t-md">
                <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                  Requests
                </h5>
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={handleNew}
                >
                  Apply for Request
                </button>
              </div>
              <div className="card-body">
                <Pagination
                  data={leaves}
                  setRowChange={setRowChange}
                  rowChangeUseEffect={rowChangeUseEffect}
                  pageChangeUseEffect={pageChangeUseEffect}
                  isLoading={leaveLoading}
                  count={count}
                  columns={columns}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpLeaves;
