import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useEffect } from "react";
import Timer from "./Timer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttendance } from "../../redux/features/attendanceSlice";
import { toast } from "react-toastify";
import Profile from "./Profile";

const Navbar = () => {
  const { setActiveMenu, portal, setPortal, screenSize, setScreenSize } =
    useStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const attendance = useSelector((state) => state.attendance);

  const handlePortal = (e) => {
    const updatedValue = e.target.value;
    const profile = JSON.parse(localStorage.getItem("profile"));
    profile.portal = updatedValue;

    localStorage.setItem("profile", JSON.stringify(profile));

    setPortal(updatedValue);
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 1024) {
      setActiveMenu(true);
    } else {
      setActiveMenu(false);
    }
  }, [screenSize, dispatch]);

  const profile = JSON.parse(localStorage.getItem("profile"));
  const roleString = profile?.role;
  const role = roleString ? JSON.parse(roleString?.replace(/'/g, '"')) : null; //convert string to array

  return (
    <>
      <header className={"w-auto left-10"}>
        <div className="flex items-center justify-end gap-4 pr-8 h-full pt-3">
          <div>
            {attendance.attendance?.check_in || attendance.checkinAt ? (
              <Timer
                stopTimer={
                  attendance.attendance?.check_out || attendance.checkoutAt
                }
              />
            ) : null}
          </div>
          <div className="flex items-center gap-2 pr-4 h-full">
            {role?.length === 1 && role.includes("employee") ? null : (
              <div className="flex items-center justify-end cursor-pointer">
                <select
                  defaultValue={portal}
                  onChange={(e) => handlePortal(e)}
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 cursor-pointer"
                >
                  {role?.includes("admin") && (
                    <option value="admin">Admin</option>
                  )}
                  {role?.includes("team_lead") && (
                    <option value="team_lead">Team Lead</option>
                  )}
                  {role?.includes("employee") && (
                    <option value="employee">User</option>
                  )}
                </select>
              </div>
            )}

            <span className="flex items-center gap-2 ml-2">
              <span className=" text-xl text-gray-500/60">
                <Profile
                  username={
                    localStorage.getItem("profile")
                      ? JSON.parse(localStorage.profile).username
                      : null
                  }
                />
              </span>
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
