import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  announcements: [],
  latestAnnouncement: "",
  count: 0,
  error: "",
};
export const getAnnouncements = createAsyncThunk(
  "announcements",
  async ({ toast, navigate, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getAnnouncements(page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestAnnouncement = createAsyncThunk(
  "latest_announcements",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await api.getLatestAnnouncement();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAnnouncements = createAsyncThunk(
  "delete-announcements",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteAnnouncements(uidb64);
      toast.success("Deleted Successfully");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAnnouncements = createAsyncThunk(
  "edit-Announcements",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editAnnouncements(uidb64, formValues);
      toast.success("Updated Successfully");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAnnouncements = createAsyncThunk(
  "add-announcements",
  async (
    { formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.addAnnouncements(formValues);
      toast.success("Added Successfully");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (action.meta.arg.rowChange|| action.meta.arg.isInitialLoad){
            state.announcements = action.payload.results
        }
        else
        {
          state.announcements = state.announcements.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getLatestAnnouncement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestAnnouncement.fulfilled, (state, action) => {
        state.loading = false;
        state.latestAnnouncement = action.payload
      })
      .addCase(getLatestAnnouncement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        const announcements = state.announcements.filter(
          (announcement) => announcement.id !== action.payload
        );
        state.announcements = [...announcements];
      })
      .addCase(deleteAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAnnouncements.fulfilled, (state, action) => {
        const announcements = state.announcements;
        state.loading = false;
        state.announcements = [action.payload, ...announcements];
      })
      .addCase(addAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editAnnouncements.pending, (state) => {
        state.loading = true;
      })
      .addCase(editAnnouncements.fulfilled, (state, action) => {
        const { id } = action.payload;
        const announcements = state.announcements.filter((announcement) => announcement.id !== id);
        state.loading = false;
        state.announcements = [action.payload, ...announcements];
      })
      .addCase(editAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllAnnouncements = (state) => state.announcement.announcements;
export const selectAnnouncementLoading = (state) => state.announcement.loading;

export const selectLatestAnnouncement = (state) => state.announcement.latestAnnouncement;

export const selectAnnouncementsCount = (state) => state.announcement.count

export default announcementSlice.reducer;
