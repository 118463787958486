import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssets,
  editAssets,
  addAssets,
  deleteAssets,
  addAssignAssets,
  editAssignAssets,
  deleteAssignAssets,
  selectAllAssets,
  selectAssetsLoading,
  selectAssetsCount,
} from "../../redux/features/assetsSlice";
import {
  getAllEmployeesNames,
  selectEmployeeNames,
} from "../../redux/features/employeesSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoPencil, IoTrashOutline, IoCloseOutline } from "react-icons/io5";
import { RotatingLinesLoader } from "../../components/Loaders";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  newAssetSchema,
  editAssetSchema,
} from "../../validation-schemas/schemas";
import { DeleteModal, EditModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";
import { BarsLoader } from "../../components/Loaders";

const Assets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employees = useSelector(selectEmployeeNames);
  const assets = useSelector(selectAllAssets);
  const assetsLoading = useSelector(selectAssetsLoading);
  const count = useSelector(selectAssetsCount);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [status, setStatus] = useState("");

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);

  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Asset");
  const [deleteId, setDeleteId] = useState("");
  const [updatedAssignee, setUpdatedAssignee] = useState("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
  } = useStateContext();

  const initialValues = {
    title: "",
    asset_model: "",
    asset_image: "",
    asset_type: "",
    cost: "",
    assignee: "",
    uidb64: "",
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setIsInitialLoad(false);
    dispatch(getAllEmployeesNames({}));
  }, [dispatch]);

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getAssets({
          status,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (assets.length < count) {
      dispatch(
        getAssets({
          status,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      assets.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAssets({
          status,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (assets.length < page * perPage &&
          (!assets.length || assets.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getAssets({
            status,
            page_size: perPage,
            page,
            rowChange,
            filterChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Image</div>,
      cell: (row) => (
        <div className="grid place-content-center w-full">
          <img
            className="mx-1 my-2 rounded-full w-12 h-12"
            src={row.asset_image}
          ></img>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Title</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.title.charAt(0).toUpperCase() + row.title.slice(1)}
        </div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Asset Model</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.asset_model}
        </div>
      ),
      selector: (row) => row.asset_model,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Asset Type</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.asset_type}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Cost</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.cost}</div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Assigned To</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.assignee ? row.assignee.assignee_name : "Unassigned"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "asset_image") {
      const selectedFile = files[0];
      setFormValues({ ...formValues, asset_image: selectedFile });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Asset");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      asset_image: row.asset_image ? row.asset_image : "",
      assignee: row.assignee?.assignee_id,
      uidb64: row.id,
    });

    setErrors({});
    setHeading("Update Asset");
    openEditModal();
    setUpdatedAssignee(row.assignee?.assignee_id);
  };

  const handleDelete = () => {
    dispatch(
      deleteAssets({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        try {
          await newAssetSchema.validate(formValues, { abortEarly: false });
          dispatch(
            addAssets({
              formValues,
              toast,
              navigate,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add employee", err);
      }
    } else {
      try {
        try {
          await editAssetSchema.validate(formValues, { abortEarly: false });
          const { uidb64, assignee, asset_image, ...updatedFormValues } =
            formValues;
          if (typeof asset_image !== "object" && assignee === updatedAssignee) {
            dispatch(
              editAssets({
                uidb64,
                formValues: updatedFormValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          } else if (
            typeof asset_image !== "object" &&
            assignee !== updatedAssignee
          ) {
            dispatch(
              editAssets({
                uidb64,
                formValues: { ...updatedFormValues, assignee: assignee },
                toast,
                closeEditModal,
                setEnable,
              })
            );
          } else if (
            typeof asset_image === "object" &&
            assignee === updatedAssignee
          ) {
            dispatch(
              editAssets({
                uidb64,
                formValues: { ...updatedFormValues, asset_image: asset_image },
                toast,
                closeEditModal,
                setEnable,
              })
            );
          } else {
            dispatch(
              editAssets({
                uidb64,
                formValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
          }
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update employee", err);
      }
    }
  };

  const status_filter = (e) => {
    const status = e.target.value;
    setFilterChange(true);
    setStatus(status);
  };

  return (
    <div>
      {assetsLoading ? (
        <div className="dashboard-min-height grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <div>
          <EditModal>
            <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
                <IoCloseOutline
                  className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={closeEditModal}
                />
              </div>
              <div className="m-1">
                <form onSubmit={handleSubmit}>
                  <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">Title</label>
                      <input
                        value={formValues.title}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="text"
                        name="title"
                      />
                      {errors.title && (
                        <p className="form-errors">{errors.title}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Asset Model
                      </label>
                      <input
                        value={formValues.asset_model}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="text"
                        name="asset_model"
                      />
                      {errors.asset_model && (
                        <p className="form-errors">{errors.asset_model}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Asset Type
                      </label>
                      <input
                        value={formValues.asset_type}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="text"
                        name="asset_type"
                      />
                      {errors.asset_type && (
                        <p className="form-errors">{errors.asset_type}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">Cost</label>
                      <input
                        value={formValues.cost}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="number"
                        name="cost"
                      />
                      {errors.cost && (
                        <p className="form-errors">{errors.cost}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Asset Image
                      </label>
                      <input
                        // value={formValues.profile_pic}
                        defaultValue={formValues.asset_image}
                        onChange={handleChange}
                        className="w-full px-2 py-[5px] text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="file"
                        accept="image/*"
                        name="asset_image"
                      />
                      {errors.asset_image && (
                        <p className="form-errors">{errors.asset_image}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Assigned To
                      </label>
                      <select
                        value={formValues.assignee}
                        onChange={handleChange}
                        className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        name="assignee"
                      >
                        <option value="Select"></option>
                        {employees
                          ? employees
                              .filter(
                                (employee) =>
                                  employee?.employee_status === "WORKING"
                              )
                              .map((val, index) => (
                                <option key={index} value={val.id}>
                                  {val.employee_name}
                                </option>
                              ))
                          : null}
                      </select>
                      {errors.assignee && (
                        <p className="form-errors">{errors.assignee}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                    <div className="flex gap-4">
                      {enable ? (
                        <button
                          className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                          type="submit"
                        >
                          Save
                        </button>
                      ) : (
                        <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                          <RotatingLinesLoader />
                        </div>
                      )}
                      <button
                        type="button"
                        className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        onClick={closeEditModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </EditModal>

          <DeleteModal handleDelete={handleDelete} />

          <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
            <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
              <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
                <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                  Assets
                </h5>
                <div className="flex flex-col md:flex-row gap-2 md:items-center max-md:items-start">
                  <div className="">
                    <select
                      className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                      id="choice"
                      defaultValue=""
                      onChange={(e) => status_filter(e)}
                    >
                      <option value="">All Assets</option>
                      <option value="assigned">Assigned</option>
                      <option value="unassigned">Un-Assigned</option>
                    </select>
                  </div>
                  <div>
                    <button
                      className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                      onClick={handleNew}
                    >
                      Add Assets
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Pagination
                  data={assets}
                  filterChange={filterChange}
                  filterUseEffect={filterUseEffect}
                  setRowChange={setRowChange}
                  rowChangeUseEffect={rowChangeUseEffect}
                  pageChangeUseEffect={pageChangeUseEffect}
                  isLoading={assetsLoading}
                  count={count}
                  columns={columns}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assets;
