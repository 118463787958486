import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  empPayroll,
  selectEmployeePayrolls,
  selectPayrollLoading,
  selectPayrollCount,
} from "../../redux/features/payrollSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IoTrendingUpOutline,
  IoTrendingDownOutline,
  IoPrintOutline,
  IoEyeOutline,
} from "react-icons/io5";
import jsPDF from "jspdf";
import { BarsLoader } from "../../components/Loaders";
import numWords from "num-words";
import logo from "../../assets/logo.png";
import Pagination from "../../components/Pagination";

const EmpPayrol = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payroll = useSelector(selectEmployeePayrolls);
  const payrollLoading = useSelector(selectPayrollLoading);
  const count = useSelector(selectPayrollCount);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [rowChange, setRowChange] = useState(false);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const rowChangeUseEffect = () => {
    if (payroll.length < count) {
      dispatch(
        empPayroll({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      payroll.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        empPayroll({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (payroll.length < page * perPage &&
          (!payroll.length || payroll.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          empPayroll({
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employee_name}
        </div>
      ),
      selector: (row) => row.employee_name,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Salary</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.basic_salary}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Bonus</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.bonus}</div>
      ),
    },
    {
      name: (
        <div className="text-center w-full flex gap-1 items-center text-slate-600">
          <div>Reimbursed</div>
          <IoTrendingUpOutline className="text-emerald-500" />
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.reimbursement}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full flex gap-1 items-center text-slate-600">
          <div>Tax Deduction</div>
          <IoTrendingDownOutline className="text-red-500" />
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.tax_deduction}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Travel Allowance
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.travel_allowance}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Month</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {snakeToTitleCase(row.month)}
        </div>
      ),
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Year</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.year}</div>
      ),
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Released</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.released ? "bg-emerald-600" : "bg-sky-600"
            }`}
          >
            {row.released ? <span>Released</span> : <span>Pending</span>}
          </span>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Actions</div>,
      cell: (row) => (
        <>
          <div className="text-center w-full">
            <button
              className="cursor-pointer px-3 py-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => genPdf(row, "download")}
            >
              <IoPrintOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
          <div className="text-center w-full">
            <button
              className="cursor-pointer px-3 py-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => genPdf(row, "view")}
            >
              <IoEyeOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        </>
      ),
    },
  ];

  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getFormattedMonthYear = (month, year) => {
    const monthNames = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    const monthIndex = monthNames.indexOf(month.toLowerCase());

    if (monthIndex === -1) {
      return "Invalid Month";
    }

    const date = new Date(year, monthIndex, 1);

    const formattedDate = date.toLocaleDateString(undefined, {
      year: "2-digit",
      month: "long",
    });

    return formattedDate;
  };

  const formatAmount = (num) => {
    if (typeof num === "string") {
      const convertedStr = Number(num);
      return convertedStr?.toLocaleString();
    } else {
      return num?.toLocaleString();
    }
  };

  const formatAmountToWords = (num) => {
    if (typeof num === "string") {
      const convertedNumber = Number(num);
      if (isNaN(convertedNumber)) {
        // If NaN, return the original string
        return `Sorry N/A for ${num}`;
      } else {
        // If not NaN, return the formatted number
        return numWords(convertedNumber);
      }
    } else {
      return numWords(num);
    }
  };
  function capitalizeFirstLetterOfEachWord(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  const genPdf = (row, type) => {
    // const string = renderToString(<PdfFile props={row} />);
    var jspdf = new jsPDF("a4");

    const logoImage = new Image();
    logoImage.src = logo;

    jspdf.addImage(logoImage, "png", 20, 20, 30, 5);
    jspdf.setFont("times", "normal");
    jspdf.setFontSize(10);
    jspdf.text("3rd Floor 48-49 Block A", 155, 25);
    jspdf.text("Revenue Housing Society", 155, 30);
    jspdf.text("Johar Town  Lahore, Punjab", 155, 35);

    jspdf.setFontSize(20);
    jspdf.text("Salary Slip", 100, 45, "center");

    jspdf.setFontSize(12);
    jspdf.text("Employee Name: ", 20, 70);

    jspdf.setFontSize(10);
    jspdf.text(row?.employee_name, 70, 70);

    jspdf.setFontSize(12);
    jspdf.text("Designation: ", 20, 80);
    var employee_name = row?.employee_designation.toLowerCase();
    var capataliz_employe_name = capitalizeFirstLetterOfEachWord(employee_name);
    jspdf.setFontSize(10);
    jspdf.text(capataliz_employe_name, 70, 80);

    jspdf.setFontSize(12);
    jspdf.text("Employment Status: ", 20, 90);

    jspdf.setFontSize(10);
    jspdf.text("Permanent", 70, 90);

    jspdf.setFontSize(12);
    jspdf.text("Month and year:", 20, 100);

    jspdf.setFontSize(10);
    jspdf.text(getFormattedMonthYear(row?.month, row?.year), 70, 100);

    jspdf.setFontSize(14);
    jspdf.setDrawColor(0, 0, 0);
    jspdf.text("Salary Details in Rupees", 20, 115);
    jspdf.text("PKR", 160, 115);
    let dimension = 130;
    jspdf.setFontSize(10);
    jspdf.text("Basic Salary:", 20, dimension);
    jspdf.text(
      row?.basic_salary ? formatAmount(row?.basic_salary) : "-",
      160,
      dimension
    );
    if (row?.config?.overtime !== 0 && row?.config?.overtime !== null) {
      dimension += 10;
      jspdf.text("Overtime:", 20, dimension);
      jspdf.text(
        row?.config?.overtime ? formatAmount(row?.config?.overtime) : "-",
        160,
        dimension
      );
    }
    if (
      row?.config?.project_commission !== 0 &&
      row?.config?.project_commission !== null
    ) {
      dimension += 10;
      jspdf.text("Project Commission:", 20, dimension);
      jspdf.text(
        row?.config?.project_commission
          ? formatAmount(row?.config?.project_commission)
          : "-",
        160,
        dimension
      );
    }

    if (row?.bonus !== 0 && row?.bonus !== null) {
      dimension += 10;
      jspdf.text("Bonus:", 20, dimension);
      jspdf.text(row?.bonus ? formatAmount(row?.bonus) : "-", 160, dimension);
    }
    if (row?.config?.allowance !== 0 && row?.config?.allowance !== null) {
      dimension += 10;
      jspdf.text("Allowance:", 20, dimension);
      jspdf.text(
        row?.config?.allowance ? formatAmount(row?.config?.allowance) : "-",
        160,
        dimension
      );
    }

    if (
      row?.config?.late_sitting_bonus !== 0 &&
      row?.config?.late_sitting_bonus !== null
    ) {
      dimension += 10;
      jspdf.text("Late Sitting Bonus:", 20, dimension);
      jspdf.text(
        row?.config?.late_sitting_bonus
          ? formatAmount(row?.config?.late_sitting_bonus)
          : "-",
        160,
        dimension
      );
    }

    let gross_salary = row.basic_salary + row.travel_allowance + row.bonus;
    jspdf.setFontSize(14);
    dimension += 10;
    jspdf.text("Gross Salary:", 20, dimension);
    jspdf.text(gross_salary ? formatAmount(gross_salary) : "-", 160, dimension);

    if (row?.tax_deduction !== 0 && row?.tax_deduction !== null) {
      dimension += 15;
      jspdf.setFontSize(12);
      jspdf.text("Tax Deduction:", 20, dimension);
      jspdf.text(`(${formatAmount(row.tax_deduction)})`, 160, dimension);
    }

    dimension += 3;

    jspdf.line(155, dimension, 175, dimension);

    jspdf.setFontSize(14);
    dimension += 17;
    jspdf.text("Net Salary:", 20, dimension);
    jspdf.text(
      row?.config?.total_salary ? formatAmount(row?.config?.total_salary) : "-",
      160,
      dimension
    );
    dimension += 2;
    jspdf.line(155, dimension, 175, dimension);
    dimension += 0.5;
    jspdf.line(155, dimension, 175, dimension);

    dimension += 12.5;

    jspdf.text("Amount in Words:", 20, dimension);
    var total_salary_in_words = formatAmountToWords(row?.config?.total_salary);
    var total_salary_capitalize =
      total_salary_in_words.charAt(0).toUpperCase() +
      total_salary_in_words.slice(1);
    jspdf.text(
      row?.config?.total_salary ? total_salary_capitalize : "N/A in Words",
      60,
      dimension
    );

    jspdf.line(20, 260, 70, 260);
    jspdf.line(20, 260.1, 70, 260.1);
    jspdf.text("Muhammad Akhtar", 26, 266);

    jspdf.setFontSize(10);
    jspdf.text("Chief Executive Officer", 28, 272);

    jspdf.setFontSize(10);
    jspdf.text("contact@inovaqo.com", 20, 290);
    jspdf.text("+92 322 7878708", 93, 290);

    jspdf.text("https://inovaqo.com/", 160, 290);

    if (type === "download") {
      jspdf.save(
        "Payroll_" +
          row?.employee_name.toUpperCase().replace(/ /g, "_") +
          "_" +
          row?.month +
          "_" +
          row?.year
      );
    } else {
      var pdfUrl = jspdf.output("bloburl");
      window.open(pdfUrl);
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-between lg:flex-row items-center mx-3 rounded-md">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full rounded-md">
          <div className="mb-0 p-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">Payrolls</h5>
          </div>
          <div className="card-body">
            <Pagination
              data={payroll}
              setRowChange={setRowChange}
              rowChangeUseEffect={rowChangeUseEffect}
              pageChangeUseEffect={pageChangeUseEffect}
              isLoading={payrollLoading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpPayrol;
