import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  tasks: [],
  empTasks: [],
  count: 0,
  error: "",
};
export const getTasks = createAsyncThunk(
  "tasks",
  async ({ empId, employee, status, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getTasks(empId, employee, status, page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTasks = createAsyncThunk(
  "delete-tasks",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteTasks(uidb64);
      toast.success("Deleted Successfully");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTasks = createAsyncThunk(
  "edit-tasks",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editTasks(uidb64, formValues);
      toast.success("Updated Successfully");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "update_status",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.statusUpdate(uidb64, formValues);
      if (formValues.status)
        toast.success("Status Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      if (error.response.status === 403) {
        toast.error(error.response.data.detail);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTasks = createAsyncThunk(
  "add-tasks",
  async (
    { formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.addTasks(formValues);
      toast.success("Added Successfully");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.loading = false;

        state.count = action.payload.count;
        if (action.meta.arg.rowChange || action.meta.arg.filterChange ||
          action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad) {
          state.tasks = action.payload.results
        }
        else {

          state.tasks = state.tasks.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTasks.fulfilled, (state, action) => {
        state.loading = false;
        const tasks = state.tasks.filter(
          (task) => task.id !== action.payload
        );
        state.tasks = [...tasks];
      })
      .addCase(deleteTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(addTasks.fulfilled, (state, action) => {
        const tasks = state.tasks;
        state.loading = false;
        state.tasks = [action.payload, ...tasks];
      })
      .addCase(addTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(editTasks.fulfilled, (state, action) => {
        const { id } = action.payload;
        const tasks = state.tasks.filter((task) => task.id !== id);
        state.loading = false;
        state.tasks = [action.payload, ...tasks];
      })
      .addCase(editTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const { id } = action.payload;
        const tasks = state.tasks.filter((task) => task.id !== id);

        state.loading = false;
        state.tasks = [action.payload, ...tasks];
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

  },
});

export const selectAllTasks = (state) => state.task.tasks;
export const selectTaskLoading = (state) => state.task.loading;

export const selectTasksCount = (state) => state.task.count

export default taskSlice.reducer;