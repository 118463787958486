import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import { getUsersAttendance } from "./AdminAttendanceSlice";

const initialState = {
  loading: false,
  all_leaves: [],
  user_leaves: [],
  all_leaves_count: 0,
  leaves_count: 0,
  error: "",
};

export const getLeaves = createAsyncThunk(
  "get_leave",
  async ({ toast, page_size, page, portal }, { rejectWithValue }) => {
    try {
      const response = await api.getLeaves(page_size, page, portal);

      if (response.data.data === "") {
        toast.error("No Record Found");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllLeaves = createAsyncThunk(
  "list-leaves",
  async ({ emp, leave_status, leave_type, page_size, page, portal }, { rejectWithValue }) => {
    try {

      const response = await api.getAllLeaves(emp, leave_status, leave_type, page_size, page, portal);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "update_status",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.updateStatus(uidb64, formValues);
      if (formValues.status)
        toast.success("Status Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      if (error.response.status === 403) {
        toast.error(error.response.data.detail);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const applyLeave = createAsyncThunk(
  "apply-leave",
  async (
    { formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.applyLeave(formValues);
      toast.success("Leave Applied");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editLeave = createAsyncThunk(
  "edit-leave",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editLeave(uidb64, formValues);
      toast.success("Leave Edited");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLeave = createAsyncThunk(
  "delete-leave",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteLeave(uidb64);
      toast.success("Leave Deleted");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.respones.data);
    }
  }
);

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLeaves.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLeaves.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.results) {
          state.leaves_count = action.payload.count
          if (action.meta.arg.rowChange || action.meta.arg.isInitialLoad) {
            state.user_leaves = action.payload.results.data
          }
          else {
            state.user_leaves = state.user_leaves.concat(action.payload.results.data) // concat the data in previous for pagination
          }
        }
        else {
          state.user_leaves = []
        }
      })
      .addCase(getLeaves.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllLeaves.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLeaves.fulfilled, (state, action) => {
        state.loading = false;
        state.all_leaves_count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.filterChange
          || action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad) { // to load data from start when pageSize and filter apply
          state.all_leaves = action.payload.results
        }
        else {
          state.all_leaves = state.all_leaves.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getUsersAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllLeaves.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const { id } = action.payload;
        const all_leaves = state.all_leaves.filter((leave) => leave.id !== id);

        state.loading = false;
        state.all_leaves = [action.payload, ...all_leaves];
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(applyLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(applyLeave.fulfilled, (state, action) => {
        state.loading = false;
        const user_leaves = state.user_leaves;

        state.user_leaves = [action.payload, ...user_leaves];
      })
      .addCase(applyLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(editLeave.fulfilled, (state, action) => {
        const { id } = action.payload;
        const user_leaves = state.user_leaves.filter(
          (leave) => leave.id !== id
        );

        state.loading = false;
        state.user_leaves = [action.payload, ...user_leaves];
      })
      .addCase(editLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.loading = false;

        const all_leaves = state.all_leaves.filter(
          (leave) => leave.id !== action.payload
        );

        const user_leaves = state.user_leaves.filter(
          (leave) => leave.id !== action.payload
        );

        state.all_leaves = [...all_leaves];
        state.user_leaves = [...user_leaves];
      })
      .addCase(deleteLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllLeaves = (state) => state.leave.all_leaves;
export const selectUserLeaves = (state) => state.leave.user_leaves;
export const selectLeavesLoading = (state) => state.leave.loading;

export const selectAllLeavesCount = (state) => state.leave.all_leaves_count;

export const selectLeavesCount = (state) => state.leave.leaves_count;

export default leaveSlice.reducer;
