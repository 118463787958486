import React, { useEffect, useState } from "react";
import {
  IoPencil,
  IoTrashOutline,
  IoCloseOutline, IoChevronBackSharp
} from "react-icons/io5";
import {
    getEmployeeHistory, selectEmployeeNames, getAllEmployeesNames,
    selectEmployeeHistory,
    selectEmployeeHistoryCount,
    addEmployeeHistory, editEmployeeHistory, deleteEmployeeHistory
} from "../../redux/features/employeesSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    EmployeeHistorySchema
} from "../../validation-schemas/schemas";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { EditModal, DeleteModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";
import {RotatingLinesLoader} from "../../components/Loaders";

const EmployeeHistory = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { empId, employeeName } = useParams();
    const employeeDetailList = useSelector(selectEmployeeNames)
    const [deleteId, setDeleteId] = useState("");

    const employees = useSelector(selectEmployeeHistory);
    const count = useSelector(selectEmployeeHistoryCount)
    const employeeLoading = useSelector((state) => state.employees.historyLoading);


    const [errors, setErrors] = useState({});
    const [heading, setHeading] = useState("Add Employee History");
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1)

    const [rowChange, setRowChange] = useState(false)


    const {
        openEditModal,
        closeEditModal,
        enable,
        setEnable,
        openDeleteModal,
        closeDeleteModal,
        isToday,
    } = useStateContext();

    const initialValues = {
        employee: empId,
        subject: "",
        remarks: "",
        increment: "",
        interval_from: "",
        interval_to: "",
        review_by: "",
        review_date: "",
        added_by: JSON.parse(localStorage.profile).id,
        uidb64: ""
    };

    const [formValues, setFormValues] = useState(initialValues);

   useEffect(() => {
     setIsInitialLoad(false)
     if(!employeeDetailList.length){
      dispatch(getAllEmployeesNames({}))}
   }, []);

   const rowChangeUseEffect =() => {
    if(employees.length < count){
    dispatch(getEmployeeHistory({
         emp_id: empId, page_size: perPage *page , page:1, rowChange
        }) ) }
    setRowChange(false)
  }

  const pageChangeUseEffect =() => {
    if(employees.length < (page-1) * perPage && page === Math.ceil(count/perPage)) {
        dispatch(getEmployeeHistory({
         emp_id: empId, page_size: perPage *page , page:1, rowChange: true
        }) )
    }
    else{if ((employees.length < page * perPage && (!employees.length || employees.length < count)) || isInitialLoad ) {
        dispatch(getEmployeeHistory({
            emp_id: empId, page_size: perPage, page, rowChange, isInitialLoad
        }))
      }
    }
  }

    const columns = [
      {
        name: ( <div className="text-center w-full text-slate-600">Subject</div> ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">{row.subject}</div>
        ),
      },
      {
        name: (
          <div className="text-center w-full text-slate-600">Remarks</div>
        ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {row.remarks}
          </div>
        ),
      },
       {
        name: (
          <div className="text-center w-full text-slate-600">Increment</div>
        ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {row.increment.toFixed(3).replace(/\.?0*$/, '')}
          </div>
        ),
      },
      {
        name: (
          <div className="text-center w-full text-slate-600">Interval From</div>
        ),
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {isToday(row.interval_from)
            ? "Today"
            : moment(row.interval_from).format("MMM. DD, YYYY")}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Interval To</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {isToday(row.interval_to)
            ? "Today"
            : moment(row.interval_to).format("MMM. DD, YYYY")}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Review By</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {row.review_by ? row.review_by.review_by_name : ''}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Review Date</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {isToday(row.review_date)
            ? "Today"
            : moment(row.review_date).format("MMM. DD, YYYY")}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Added By</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {row.added_by ? row.added_by.added_by_name : ''}
          </div>
        ),
      },
      {
        name: <div className="text-center w-full text-slate-600">Added Date</div>,
        cell: (row) => (
          <div className="text-center w-full text-slate-500">
            {isToday(row.added_date)
            ? "Today"
            : moment(row.added_date).format("MMM. DD, YYYY")}
          </div>
        ),
      },
      {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
    ];
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { uidb64 } = formValues;
        if (
          formValues.uidb64 === "" ||
          formValues.uidb64 === null ||
          formValues.uidb64 === undefined
        ) {
      try {
        const updatedValues = {
          ...formValues,
        };
        try {
          await EmployeeHistorySchema.validate(formValues, { abortEarly: false });
          dispatch(
            addEmployeeHistory({
              formValues: { ...updatedValues },
              toast,
              navigate,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add employee history", err);
      }
    } else {
      try {
        try {
          await EmployeeHistorySchema.validate(formValues, { abortEarly: false });
            dispatch(
              editEmployeeHistory({
                uidb64,
                formValues,
                toast,
                closeEditModal,
                setEnable,
              })
            );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update employee", err);
      }
    }
  };

    const handleEdit = (row) => {
    setFormValues({
      ...row,
      review_by: row.review_by.review_by_id,
      employee: empId,
      added_by: row.added_by.added_by_id,
      uidb64: row.id,
    });

    setErrors({});
    setHeading("Update Employee History");
    openEditModal();
  };

  const handleDelete = () => {
    dispatch(
      deleteEmployeeHistory({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
     setFormValues({ ...formValues, [name]: value });
  };

    const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Employee History");
    openEditModal();
  };
    return (
    <div>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Subject</label>
                  <input
                    value={formValues.subject}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="subject"
                  />
                  {errors.subject&& (
                    <p className="form-errors">{errors.subject}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Remarks</label>
                  <input
                    value={formValues.remarks}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="remarks"
                  />
                  {errors.remarks && (
                    <p className="form-errors">{errors.remarks}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Increment</label>
                  <input
                    value={formValues.increment}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="text"
                    name="increment"
                  />
                  {errors.increment && (
                    <p className="form-errors">{errors.increment}</p>
                  )}
                </div>
                  <div>
                  <label className="ml-1 mb-1 text-slate-600">Interval From</label>
                  <input
                    value={formValues.interval_from}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="date"
                    onChange={handleChange}
                    name="interval_from"
                  />
                  {errors.interval_from && (
                    <p className="form-errors">{errors.interval_from}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Interval To</label>
                  <input
                    value={formValues.interval_to}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="date"
                    onChange={handleChange}
                    name="interval_to"
                    min={formValues.interval_from}
                  />
                  {errors.interval_to && (
                    <p className="form-errors">{errors.interval_to}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Review by</label>
                  <select
                    value={formValues.review_by}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="review_by"
                  >

                  <option value="">Select </option>
                    {employeeDetailList
                      ? employeeDetailList.map((val, index) => (
                          val.employee_status === "WORKING" &&
                          <option key={index} value={val.id}>
                          {val.employee_name}
                        </option>
                        ))
                      : null}
                  </select>
                  {errors.review_by && (
                    <p className="form-errors">{errors.review_by}</p>
                  )}
                </div>
                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Review Date
                  </label>
                  <input
                    value={formValues.review_date}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="date"
                    name="review_date"
                    max={formValues.review_date}
                  />
                  {errors.review_date && (
                    <p className="form-errors">{errors.review_date}</p>
                  )}
                </div>
              </div>
                <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                 <div className="flex-grow flex justify-between items-center">
                      <div className="flex gap-4 justify-end">
                        {enable ? (
                          <button
                            className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                            type="submit"
                          >
                            Save
                          </button>
                        ) : (
                          <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                            <RotatingLinesLoader />
                          </div>
                        )}
                        <button
                          type="button"
                          className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                          onClick={closeEditModal}
                        >
                          Close
                        </button>
                      </div>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
          <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
             <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                <span className="text-emerald-500/90">
                  { employeeName}&#39;s
                </span>{" "}
                 History
            </h5>
          <div>
            <button
              className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
              onClick={handleNew}
              style={{ marginLeft: '30px' }}
            >
              Add
            </button>
          </div>
          </div>
          <div className="card-body">
            <Pagination
                data = {employees}
                setRowChange = {setRowChange}
                rowChangeUseEffect = {rowChangeUseEffect}
                pageChangeUseEffect = {pageChangeUseEffect}
                isLoading = {employeeLoading}
                count = {count}
                columns = {columns}
                page = {page}
                perPage = {perPage}
                setPerPage = {setPerPage}
                setPage = {setPage}
            />
          <div/>
          </div>
            <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
              <button
                className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
                onClick={() => navigate(`/employee/${empId}`)}
              >
                <IoChevronBackSharp />
              </button>
            </div>
          </div>

      </div>
    </div>
  );

}

export default EmployeeHistory;