import * as Yup from "yup";

export const newEmployeeSchema = Yup.object({
  first_name: Yup.string().min(1).required("First name is required!"),
  last_name: Yup.string().min(1).required("Last name is required!"),
  username: Yup.string().min(1).required("Username name is required!"),
  email: Yup.string().email().required("Email is required!"),
  password: Yup.string().required("Please generate password!"),
  phone_number: Yup.string()
    .matches(
      /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
      "Enter a Pakistani operated number!"
    )
    .required("Phone number is required!"),
  national_id_number: Yup.string()
    .min(13, "CNIC is required!")
    .max(13, "CNIC is required!")
    .required("CNIC is required!"),
  emergency_contact_number: Yup.string().matches(
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    "Enter a Pakistani operated number!"
  ),
  gender: Yup.string().required("Please choose a gender!"),
  department: Yup.string().required("Please choose a department!"),
  designation: Yup.string().required("Please choose a designation!"),
  bank: Yup.string().required("Bank info required!"),
  account_number: Yup.string().required("Account number required!"),
  profile_pic: Yup.mixed(),
  joining_date: Yup.string().required("Please choose a joining date!"),
  employee_status: Yup.string().required("Please choose employee status!"),
  uidb64: Yup.string(),
});

export const editEmployeeSchema = Yup.object({
  first_name: Yup.string().min(1).required("First name is required!"),
  last_name: Yup.string().min(1).required("First name is required!"),
  username: Yup.string(),
  email: Yup.string().email().required("Email is required!"),
  phone_number: Yup.string()
    .matches(
      /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
      "Enter a Pakistani operated number"
    )
    .required("Phone number is required!"),
  national_id_number: Yup.string()
    .min(13, "CNIC must be 13 digits!")
    .max(13, "CNIC must be 13 digits!")
    .required("CNIC is required!"),
  emergency_contact_number: Yup.string().matches(
    /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    "Enter a Pakistani operated number!"
  ),
  gender: Yup.string().required("Please choose a gender!"),
  department: Yup.string().required("Please choose a department!"),
  designation: Yup.string().required("Please choose a designation!"),
  bank: Yup.string().required("Bank info required!"),
  account_number: Yup.string().required("Account number required!"),
  profile_pic: Yup.mixed(),
  joining_date: Yup.string().required("Please choose a joining date!"),
  employee_status: Yup.string().required("Please choose employee status!"),
  password: Yup.string(),
  uidb64: Yup.string(),
});

export const EmployeeHistorySchema = Yup.object({
  employee: Yup.string().required("Please login again to apply!"),
  subject: Yup.string().required("Subject is required!"),
  remarks: Yup.string().required("Remarks is required!"),
  increment: Yup.number().required("Increment is required!"),
  interval_from: Yup.string().required("Please select interval from!"),
  interval_to: Yup.string().required("Please select interval to!"),
  review_by: Yup.string().required("Please choose review by"),
  review_date: Yup.string().required("Please select review date"),
  added_by: Yup.string(),
  uidb64: Yup.string(),
});

export const departmentSchema = Yup.object({
  department_name: Yup.string().min(1).required("Department name is required!"),
  description: Yup.string().min(1).required("Description is required!"),
  uidb64: Yup.string(),
});

export const newApplicantSchema = Yup.object({
  first_name: Yup.string().min(1).required("First name is required!"),
  last_name: Yup.string().min(1).required("First name is required!"),
  email: Yup.string().email().required("Email is required!"),
  phone_number: Yup.string()
    .matches(
      /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
      "Enter a Pakistani operated number!"
    )
    .required("Phone number is required"),
  position: Yup.string().required("Please choose a position!"),
  resume: Yup.mixed().test(
    "fileSelected",
    "Please upload the resume!",
    function (value) {
      if (!value) {
        return this.createError({
          message: "Please upload the resume!",
          path: "resume",
        });
      }
      return true;
    }
  ),
  status: Yup.string().required("Please choose a status!"),
  referrers: Yup.string().nullable(),
  uidb64: Yup.string(),
});

export const editApplicantSchema = Yup.object({
  first_name: Yup.string().min(1).required("First name is required!"),
  last_name: Yup.string().min(1).required("First name is required!"),
  email: Yup.string().email().required("Email is required!"),
  phone_number: Yup.string()
    .matches(
      /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
      "Enter a Pakistani operated number"
    )
    .required("Phone number is required"),
  position: Yup.string().required("Please choose a position!"),
  resume: Yup.mixed(),
  status: Yup.string().required("Please choose a status!"),
  referrers: Yup.string().nullable(),
  uidb64: Yup.string(),
});

export const payrollSchema = Yup.object({
  employee: Yup.string().required("Please choose and employee!"),
  basic_salary: Yup.number("Please enter a valid number"),

  bonus: Yup.number("Please enter a valid number"),

  reimbursement: Yup.number("Please enter a valid number"),

  tax_deduction: Yup.number("Please enter a valid number"),

  travel_allowance: Yup.number("Please enter a valid number"),

  config: Yup.object({
    arrears: Yup.number("Please enter a valid number"),

    overtime: Yup.number("Please enter a valid number"),

    allowance: Yup.number("Please enter a valid number"),

    increment: Yup.number("Please enter a valid number"),

    deductions: Yup.number("Please enter a valid number"),

    loan_advance: Yup.number("Please enter a valid number"),

    total_salary: Yup.number("Please enter a valid number"),

    project_bonus: Yup.number("Please enter a valid number"),

    tax_deductions: Yup.number("Please enter a valid number"),

    late_sitting_bonus: Yup.number("Please enter a valid number"),

    project_commission: Yup.number("Please enter a valid number"),
  }),
  month: Yup.string().required("Please select a month!"),
  year: Yup.string().required("Please select a year!"),
  released: Yup.boolean(),
  uidb64: Yup.string(),
});

export const importPayrollSchema = Yup.object({
  month: Yup.string().required("Please select a month!"),
  year: Yup.string().required("Please select a year!"),
  payroll_sheet: Yup.mixed("Please upload the spreadsheet"),
});

export const leaveSchema = Yup.object({
  employee: Yup.string().required("Please login again to apply!"),
  leave_type: Yup.string().required("Please choose a type!"),
  number_of_days: Yup.number("Please enter a valid number").required(
    "No. of days required!"
  ),
  reason: Yup.string().required("Describe the reason for leave!"),
  request_date: Yup.string(),
  from_date: Yup.string().required("Please select from date!"),
  to_date: Yup.string().required("Please select to date!"),
  uidb64: Yup.string(),
});

export const newAssetSchema = Yup.object({
  title: Yup.string().required("Title is required!"),
  asset_model: Yup.string().required("Model is required!"),
  // asset_image: Yup.mixed().test(
  //   "fileSelected",
  //   "Please upload the asset image!",
  //   function (value) {
  //     if (!value) {
  //       return this.createError({
  //         message: "Please upload the asset image!",
  //         path: "asset_image",
  //       });
  //     }
  //     return true;
  //   }
  // ),
  asset_image: Yup.mixed(),
  asset_type: Yup.string().required("Type is required!"),
  cost: Yup.string("Please enter a valid number").required("Cost is required!"),
  status: Yup.string(),
  assignee: Yup.string(),
  uidb64: Yup.string(),
});

export const editAssetSchema = Yup.object({
  title: Yup.string().required("Title is required!"),
  asset_model: Yup.string().required("Model is required!"),
  asset_image: Yup.mixed(),
  asset_type: Yup.string().required("Type is required!"),
  cost: Yup.string("Please enter a valid number").required("Cost is required!"),
  status: Yup.string(),
  assignee: Yup.string(),
  uidb64: Yup.string(),
});

export const policySchema = Yup.object({
  file_name: Yup.string().required("Please add the file name!"),
  policy_file: Yup.mixed("Please upload the spreadsheet"),
});

export const announcementSchema = Yup.object({
  title: Yup.string().required("Please add the title"),
  detail: Yup.string().required("Detail is required"),
  date_from: Yup.string().nullable(),
  date_to: Yup.string().nullable()
})

export const ApplicantHistorySchema = Yup.object({
  applicant: Yup.string(),
  process_stage: Yup.string().required("This is required field!"),
  remarks: Yup.string().required("Remarks is required!"),
  event_date: Yup.string().required("This is required field!"),
  conduct_by: Yup.string().required("Please choose conduct by"),
  added_by: Yup.string(),
  uidb64: Yup.string(),
});

export const TasksSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("This is required field!"),
  deadline: Yup.string().required("This is required field!"),
  employee: Yup.string().required("Please choose assignee"),
  uidb64: Yup.string(),
});
