import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAllAttendance,
  getAttendance,
  selectAttendanceCount,
  selectUserAttendanceLoading,
  selectAllUserAttendance,
  selectTodayAttendance,
} from "../../redux/features/attendanceSlice";
import moment from "moment";
import { useStateContext } from "../../components/contexts/ContextProvider";
import Pagination from "../../components/Pagination";
import { BarsLoader } from "../../components/Loaders";

const EmpAttendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const attendance = useSelector(selectAllUserAttendance);
  const todayAttendance = useSelector(selectTodayAttendance);
  const count = useSelector(selectAttendanceCount);

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const attendanceLoading = useSelector(selectUserAttendanceLoading);

  const { isToday } = useStateContext();

  useEffect(() => {
    setIsInitialLoad(false);
    dispatch(getAttendance({ toast, navigate }));
  }, []);

  const rowChangeUseEffect = () => {
    if (attendance.length < count) {
      dispatch(
        getAllAttendance({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      attendance.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAllAttendance({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (attendance.length < page * perPage &&
          (!attendance.length || attendance.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getAllAttendance({
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.check_in_date)
            ? "Today"
            : moment(row.check_in_date).format("MMM. DD, YYYY")}
        </div>
      ),
      selector: (row) => row.check_in_date,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Check In At</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {formatTime(row.time_check_in)}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Check Out At</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {formatTime(row.time_check_out)}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Total Hours Worked
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.total_time ? row.total_time : "No Checkout Time"}
        </div>
      ),
    },
  ];

  const formatTime = (time) => {
    if (time === null || time === undefined) {
      return "";
    }

    const format = "hh:mm A";

    // Split the time into hours, minutes, and seconds
    const [hours, minutes, seconds] = time.split(":");

    // Create a moment duration with the extracted hours, minutes, and seconds
    const duration = moment.duration({
      hours: parseInt(hours),
      minutes: parseInt(minutes),
      seconds: parseInt(seconds),
    });

    // Create a moment object with zero time and add the duration
    const resultTime = moment().startOf("day").add(duration);

    return resultTime.format(format);
  };

  return (
    <div>
      {attendanceLoading ? (
        <div className="dashboard-min-height grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-between lg:flex-row items-center mx-3 rounded-md">
            <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full rounded-md">
              <div className="mb-0 p-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">
                  Attendance History
                </h5>
              </div>
              <div className="card-body">
                <Pagination
                  data={attendance === null ? todayAttendance : attendance}
                  setRowChange={setRowChange}
                  rowChangeUseEffect={rowChangeUseEffect}
                  pageChangeUseEffect={pageChangeUseEffect}
                  isLoading={attendanceLoading}
                  count={attendance === null ? 1 : count}
                  columns={columns}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpAttendance;
