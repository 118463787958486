import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  all_user_attendances: [],
  count: 0,
  error: '',
};

export const getUsersAttendance = createAsyncThunk(
  "attendance",
  async ({ emp_id, date, page_size, page, portal }, { rejectWithValue }) => {
    try {
      const response = await api.getAllEmployeesAttendance(emp_id, date, page_size, page, portal);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const AdminAttendanceSlice = createSlice({
  name: "admin_attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.filterChange
          || action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad) { // to load data from start when pageSize and filter apply
          state.all_user_attendances = action.payload.results
        }
        else {
          state.all_user_attendances = state.all_user_attendances.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getUsersAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const selectAllUserAttendance = (state) =>
  state.adminAttendance.all_user_attendances;

export const selectAttendanceCount = (state) =>
  state.adminAttendance.count;
export const selectAttendanceLoading = (state) => state.adminAttendance.loading;

export default AdminAttendanceSlice.reducer;
