import React, { useEffect, useLayoutEffect, useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { useStateContext } from "../contexts/ContextProvider";

const Timer = ({ stopTimer }) => {
  const { seconds, minutes, hours, handleStart, handlePause, handleReset } =
    useStateContext();
  const formatTime = (value) => value.toString().padStart(2, "0");
  // const format12HourTime = (value) => {
  //   return new Date(`2023-01-01T${value}:00:00`).toLocaleTimeString([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //   });
  // };

  return (
    <div className="text-center text-4xl text-emerald-400/80 font-semibold">
      <div>
        {/* <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span> */}
        <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
        <span>{formatTime(seconds)}</span>
      </div>
    </div>
  );
};

export default Timer;
