import React, { createContext, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useStopwatch } from "react-timer-hook";
import moment from "moment";
const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  Notification: false,
};

export const ContextProvider = ({ children }) => {
  const [isClicked, setIsClicked] = useState(initialState);

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(undefined);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showRemainingLeavesModal, setShowRemainingLeavesModal] =
    useState(false);
  const [showAllocatedLeavesModal, setShowAllocatedLeavesModal] =
    useState(false);

  const activeProfile = JSON.parse(localStorage.getItem("profile")) || null


  const [portal, setPortal] = useState(activeProfile?.portal)
  const [profile, setProfile] = useState(activeProfile);


  const [enable, setEnable] = useState(true);

  const timer = JSON.parse(secureLocalStorage.getItem("timer"));
  const localSeconds = timer ? parseInt(timer?.seconds) : 0;
  const localMinutes = timer ? parseInt(timer?.minutes) : 0;
  const localHours = timer ? parseInt(timer?.hours) : 0;
  const pauseTimer = localStorage.getItem("pauseTimer");

  const totalOffset = localHours * 3600 + localMinutes * 60 + localSeconds;

  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + totalOffset);

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: pauseTimer && pauseTimer !== "true" ? true : false,
    offsetTimestamp: stopwatchOffset,
  });

  const handleStart = () => {
    start();
  };

  const handlePause = () => {
    pause();
    // localStorage.setItem("pauseTimer", JSON.stringify("false"));
  };

  const handleReset = () => {
    secureLocalStorage.setItem(
      "timer",
      JSON.stringify({ seconds: 0, minutes: 0, hours: 0 })
    );
    reset();
  };

  useEffect(() => {
    secureLocalStorage.setItem("timer", JSON.stringify({ seconds, minutes, hours }));
  }, [hours, minutes, seconds]);

  const openEditModal = () => {
    setShowEditModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    document.body.style.overflow = "";
  };
  const openAllocatedLeavesModal = () => {
    setShowAllocatedLeavesModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeAllocatedLeavesModal = () => {
    setShowAllocatedLeavesModal(false);
    document.body.style.overflow = "";
  };
  const openRemainingLeavesModal = () => {
    setShowRemainingLeavesModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeRemainingLeavesModal = () => {
    setShowRemainingLeavesModal(false);
    document.body.style.overflow = "";
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    document.body.style.overflow = "";
  };

  const openImportModal = () => {
    setShowImportModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeImportModal = () => {
    setShowImportModal(false);
    document.body.style.overflow = "";
  };

  const openDetailModal = () => {
    setShowDetailModal(true);
    document.body.style.overflow = "hidden";
  };

  const closeDetailModal = () => {
    setShowDetailModal(false);
    document.body.style.overflow = "";
  };
  const isToday = (date) => {
    return moment(date).isSame(moment(), "day");
  };

  return (
    <StateContext.Provider
      value={{
        activeProfile,
        activeMenu,
        handleClick,
        setActiveMenu,
        isClicked,
        setIsClicked,
        screenSize,
        setScreenSize,
        portal,
        setPortal,
        enable,
        setEnable,
        showEditModal,
        openEditModal,
        closeEditModal,
        showDeleteModal,
        openDeleteModal,
        closeDeleteModal,
        showImportModal,
        openImportModal,
        closeImportModal,
        showDetailModal,
        openDetailModal,
        closeDetailModal,
        showAllocatedLeavesModal,
        openAllocatedLeavesModal,
        closeAllocatedLeavesModal,
        showRemainingLeavesModal,
        openRemainingLeavesModal,
        closeRemainingLeavesModal,
        seconds,
        minutes,
        hours,
        handleStart,
        handlePause,
        handleReset,
        isToday,
        profile,
        setProfile
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
