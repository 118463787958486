import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import {getUsersAttendance} from "./AdminAttendanceSlice";

const initialState = {
  loading: false,
  payrolls: [],
  employeePayroll: [],
  all_count:0,
  emp_count:0,
  error: "",
};

export const getPayroll = createAsyncThunk(
  "render_payroll",
  async ({ emp, month, year, toast, navigate, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.renderPayroll(emp, month, year, page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPayroll = createAsyncThunk(
  "add-payroll",
  async ({ formValues, toast, navigate, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addPayroll(formValues);
      toast.success("Payroll Created");
      navigate(0);
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const importPayroll = createAsyncThunk(
  "import-payroll",
  async ({ formValues, toast, navigate, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.importPayroll(formValues);
      toast.success("Payroll Imported");
      navigate(0);
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error("Unable to import");
      return rejectWithValue(error.response.data);
    }
  }
);

export const emailPayroll = createAsyncThunk(
  "email-payroll",
  async ({ formValues, toast, setEnable, navigate }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.emailPayroll(formValues);
      toast.success("Payroll sent successfully");
      navigate(0);
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error("Unable to send email");
      return rejectWithValue(error.response.data);
    }
  }
);

export const editPayroll = createAsyncThunk(
  "edit-payroll",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editPayroll(uidb64, formValues);
      toast.success("Payroll Edited");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePayroll = createAsyncThunk(
  "delete-payroll",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deletePayroll(uidb64);
      toast.success("Payroll Deleted");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

// export const filterPayroll = createAsyncThunk(
//   "filter-payroll",
//   async ({ emp, month, year, toast, navigate }, { rejectWithValue }) => {
//     try {
//       const response = await api.payrollFilter(emp, month, year);
//       // toast.success('Filter Applied Successfully')
//       return response.data.results;
//     } catch (error) {
//       toast.error(error.response.data.error);
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const empPayroll = createAsyncThunk(
  "payroll/check_payroll",
  async ({ toast, navigate, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.empPayroll(page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.all_count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.filterChange
            || action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad){ // to load data from start when pageSize and filter apply
            state.payrolls = action.payload.results
        }
        else
        {
          state.payrolls = state.payrolls.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getUsersAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPayroll.fulfilled, (state, action) => {
        const payrolls = state.payrolls;
        state.loading = false;

        state.payrolls = [action.payload, ...payrolls];
      })
      .addCase(addPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPayroll.fulfilled, (state, action) => {
        const { id } = action.payload;
        const payrolls = state.payrolls.filter((payroll) => payroll.id !== id);

        state.loading = false;
        state.payrolls = [action.payload, ...payrolls];
      })
      .addCase(editPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePayroll.fulfilled, (state, action) => {
        state.loading = false;
        const payrolls = state.payrolls.filter(
          (payroll) => payroll.id !== action.payload
        );

        state.payrolls = [...payrolls];
      })
      .addCase(deletePayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // .addCase(filterPayroll.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(filterPayroll.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.payrolls = action.payload;
      // })
      // .addCase(filterPayroll.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      .addCase(empPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(empPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.emp_count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.isInitialLoad){
            state.employeePayroll = action.payload !== ""? action.payload.results : []
        }
        else
        {
          state.employeePayroll = state.employeePayroll.concat(action.payload !== ""? action.payload.results : []) // concat the data in previous for pagination
        }
      })
      .addCase(empPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(importPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(importPayroll.fulfilled, (state, action) => {
        const payrolls = state.payrolls;
        state.loading = false;

        state.payrolls = [action.payload, ...payrolls];
      })
      .addCase(importPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(emailPayroll.pending, (state) => {
        state.loading = true;
      })
      .addCase(emailPayroll.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(emailPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectPayrolls = (state) => state.payroll.payrolls;
export const selectEmployeePayrolls = (state) => state.payroll.employeePayroll;
export const selectPayrollLoading = (state) => state.payroll.loading;
export const selectPayrollCount = (state) => state.payroll.emp_count
export const selectPayrollAllCount = (state) => state.payroll.all_count

export default payrollSlice.reducer;
