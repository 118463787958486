import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import logo1 from "../../assets/logo3.png";
import {
  IoSpeedometerOutline,
  IoPeopleOutline,
  IoCalendarOutline,
  IoTerminalOutline,
  IoIdCardOutline,
  IoBarChartOutline,
  IoPersonRemoveOutline,
  IoCashOutline,
  IoCalendarNumberOutline,
  IoLogOutOutline,
  IoFileTrayFullOutline,
  IoDocumentsOutline,
} from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttendance } from "../../redux/features/attendanceSlice";
import { toast } from "react-toastify";
import { FaTasks } from "react-icons/fa";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { portal } = useStateContext();

  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const handleCloseSideBar = () => {
    if (!activeMenu && screenSize <= 1024) {
      setActiveMenu(true);
    }
  };

  return (
    <>
      <section
        className={`left-0 top-0 h-full fixed text-white bg-emerald-950/95 transition-all ease-in-out duration-300 overflow-x-auto z-20 ${
          activeMenu ? "max-w-[200px]" : "ml-0 w-72"
        }`}
      >
        <div
          className={`absolute top-3 left-3 z-20 cursor-pointer rounded-xl hover:bg-emerald-500/50 px-2.5`}
          onClick={() => setActiveMenu((prevActiveMennu) => !prevActiveMennu)}
        >
          <span className="h-10 w-10 relative grid place-content-center font-semibold ">
            <button
              className={`text-2xl px-3 py-2 grid place-content-center ${
                activeMenu ? "" : "toggle-btn"
              }`}
            >
              <div className="h-0.5 w-7 rounded bg-white transition-all duration-500 before:absolute before:h-0.5 before:w-7 before:-translate-x-3.5 before:-translate-y-2 before:rounded before:bg-white before:transition-all before:duration-500 before:content-[''] after:absolute after:h-0.5 after:w-7 after:-translate-x-3.5 after:translate-y-2 after:rounded after:bg-white after:transition-all after:duration-500 after:content-['']"></div>
            </button>
          </span>
        </div>

        <div className="h-24 relative">
          <div className="grid place-content-center pt-5">
            {activeMenu ? (
              <Link to="/" className="mt-1">
                <div className="bg-emerald-500/50 rounded-xl w-16 py-1 grid place-content-center mt-2">
                  <img
                    src={logo1}
                    onClick={handleCloseSideBar}
                    width={40}
                    alt="ViteAce Logo"
                  />
                </div>
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={logo}
                  onClick={handleCloseSideBar}
                  width={110}
                  alt="ViteAce Logo"
                />
              </Link>
            )}
          </div>
        </div>

        <ul className={`side-links my-8 ${activeMenu ? "px-2" : "px-5"}`}>
          <li>
            <NavLink
              to="/"
              end
              onClick={handleCloseSideBar}
              className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                activeMenu ? "" : "grid-cols-2"
              }`}
            >
              <IoSpeedometerOutline
                className={`w-8 h-8 self-center py-1 ${
                  activeMenu ? "justify-self-center" : "justify-self-start"
                }`}
              />
              <span
                className={`self-center justify-self-start py-1 -ml-4 ${
                  !activeMenu ? "block" : "hidden"
                }`}
              >
                Dashboard
              </span>
            </NavLink>
          </li>
          <li
            className={`uppercase font-bold text-white mt-6 text-base ${
              activeMenu ? "hidden " : ""
            }`}
          >
            Main
          </li>

          {portal === "admin" ? (
            <>
              <li>
                <NavLink
                  to="/employees"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoPeopleOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Employees
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/department"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoTerminalOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Departments
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/assets"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoBarChartOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Assets
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/attendance"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoCalendarOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Attendance
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/leaves"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoPersonRemoveOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Requests
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/task"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <FaTasks
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Tasks
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/payrolls"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoCashOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Payrolls
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/recruits"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoIdCardOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Applicants
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/announcements/"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <FaBell
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-6 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Announcements
                  </span>
                </NavLink>
              </li>
            </>
          ) : portal === "team_lead" ? (
            <>
              <li>
                <NavLink
                  to="/employees"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoPeopleOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Employees
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/attendance"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoCalendarOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Attendance
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/leaves"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoPersonRemoveOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Requests
                  </span>
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink
                  to="/attendance/emp_attendance"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoCalendarNumberOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Attendance
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/leaves/emp_leaves"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoPersonRemoveOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Requests
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/task"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <FaTasks
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Tasks
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/payrolls/emp_payrolls"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoCashOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Payrolls
                  </span>
                </NavLink>
              </li>
            </>
          )}
          <>
            {portal !== "team_lead" ? (
              <li>
                <NavLink
                  to="/documents"
                  className={`grid text-white my-1 text-base px-3 py-2 rounded-xl ${
                    activeMenu ? "" : "grid-cols-2"
                  }`}
                >
                  <IoDocumentsOutline
                    className={`w-8 h-8 self-center py-1 ${
                      activeMenu ? "justify-self-center" : "justify-self-start"
                    }`}
                  />
                  <span
                    className={`self-center justify-self-start py-1 -ml-4 ${
                      !activeMenu ? "block" : "hidden"
                    }`}
                  >
                    Documents
                  </span>
                </NavLink>
              </li>
            ) : null}
          </>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
