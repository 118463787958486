import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import { useStateContext } from "../components/contexts/ContextProvider";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/features/authSlice";

const DefaultLayout = () => {
  const { activeMenu } = useStateContext();
  const user = useSelector(selectUser);
  const localUser = JSON.parse(localStorage.getItem("profile"));
  const loggedUser = localUser?.tokens || user;

  return (
    <>
      {loggedUser ? (
        <>
          <Sidebar />
          <Navbar />
          <div
            className={`main-content ${
              activeMenu ? "main-content-toggle" : ""
            }`}
          >
            <main>
              <div className={`page-header`}>
                <div className="home-page-content">{<Outlet />}</div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default DefaultLayout;
