import React, { useState, useRef, useEffect } from "react";
import { IoLogOutOutline, IoPersonOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { setLogout } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RotatingLinesLoader } from "../Loaders";
import { useStateContext } from "../contexts/ContextProvider";
import { resetDashboard } from "../../redux/features/dashboardSlice";

const Profile = ({ username }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dashboard = useSelector((state) => state.dashboard);
  const menuRef = useRef(null);
  const attendance = useSelector((state) => state.attendance);
  const pause = localStorage.getItem("pauseTimer");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profilePic, setProfilePic] = useState(
    localStorage.getItem("profile_pic")
  );
  const id = localStorage.getItem("profile")
    ? JSON.parse(localStorage.profile).id
    : null;
  const [isLoading, setIsLoading] = useState(true);
  const { setPortal, setProfile } = useStateContext();

  useEffect(() => {
    if (dashboard.dashboard.profile_pic) {
      const profilePicUrl = dashboard.dashboard.profile_pic;
      setProfilePic(profilePicUrl);
      localStorage.setItem("profile_pic", profilePicUrl);
    }
    setIsLoading(false);
  }, [dashboard.dashboard.profile_pic]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    const handleResize = () => {
      setIsMenuOpen(false); // Close the menu on window resize
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setIsMenuOpen(false); // Close the menu when the window is hidden (e.g., switching tabs)
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    window.addEventListener("resize", handleResize);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    if (
      pause &&
      pause !== "true" &&
      !(attendance.attendance?.check_out || attendance.checkoutAt)
    ) {
      toast.error("Please pause the timer first");
    } else {
      dispatch(setLogout());
      dispatch(resetDashboard());
      localStorage.removeItem("profile_pic");
      setProfilePic("");
      setPortal("");
      setProfile("");
      navigate("/login");
    }
  };

  const getProfileColor = () => {
    // Combine first and last names to create a unique identifier
    const fullName = `${username}`;
    // Calculate the hash code
    let hash = 0;
    for (let i = 0; i < fullName.length; i++) {
      hash = fullName?.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash code to a hexadecimal color
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  };

  const initials = `${username?.charAt(0).toUpperCase()}`;

  const buttonStyle = dashboard?.dashboard?.profile_pic
    ? { backgroundColor: "transparent" }
    : { backgroundColor: getProfileColor(), textAlign: "center" };

  return (
    <div className="profile-button" ref={menuRef}>
      <button style={buttonStyle} onClick={toggleMenu}>
        {isLoading ? (
          // initials // or any loading indicator
          <div className="mx-5 my-2 rounded-full w-full">
            <RotatingLinesLoader />
          </div>
        ) : profilePic ? (
          <img
            src={profilePic}
            alt="Profile"
            className="mx-1 my-2 rounded-full w-full h-full"
          />
        ) : (
          initials
        )}
      </button>
      {isMenuOpen && (
        <div className="menu">
          {/* Your profile options here */}
          <ul>
            <li onClick={() => navigate(`/employee/${id}`)}>
              <span className="icon">
                <IoPersonOutline />
              </span>
              My Profile
            </li>
            <li onClick={handleLogout}>
              <span className="icon">
                <IoLogOutOutline />
              </span>{" "}
              Log out{" "}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;
