import "./App.css";
import React, { Suspense, useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/features/authSlice";
import { useStateContext } from "./components/contexts/ContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import DefaultLayout from "./layout/DefaultLayout";
import Dashboard from "./components/Dashboard";
import Employees from "./pages/admin/Employees";
import EmpDetails from "./pages/admin/EmpDetails";
import Department from "./pages/admin/Department";
import Assets from "./pages/admin/Assets";
import Accounts from "./pages/admin/Accounts";
import Attendance from "./pages/admin/Attendance";
import AdminLeaves from "./pages/admin/AdminLeaves";
import Payroll from "./pages/admin/Payroll";
import Recruits from "./pages/admin/Recruits";
import Documents from "./pages/admin/Documents";
import EmpLeaves from "./pages/user/EmpLeaves";
import EmpAttendance from "./pages/user/EmpAttendance";
import EmpPayrol from "./pages/user/EmpPayrol";
import RecruitHistory from "./pages/admin/recruit_history";
import EmployeeHistory from "./pages/admin/emp_history";
import Tasks from "./pages/admin/Tasks";
import Announcements from "./pages/admin/Announcements";
// import Timer from './components/navbar/Timer';

const Signin = React.lazy(() => import("./pages/auth/Signin"));
const CreatePassword = React.lazy(() => import("./pages/auth/CreatePassword"));
const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));

function App() {
  const dispatch = useDispatch();
  const { portal } = useStateContext();

  useEffect(() => {
    const profile = localStorage.getItem("profile");
    if (profile) {
      const user = JSON.parse(profile);
      dispatch(setUser(user));
    }
  }, [dispatch]);

  const router = createBrowserRouter([
    {
      path: "/login",
      exact: true,
      name: "Login Page",
      element: <Signin />,
    },
    {
      path: "/auth/reset_password",
      name: "Reset Password",
      element: <ResetPassword />,
    },
    {
      path: "/auth/password-reset/:uidb64/:token",
      name: "Reset Password",
      element: <CreatePassword />,
    },
    {
      path: "/",
      exact: true,
      name: "Home",
      element: <DefaultLayout />,

      children:
        portal === "admin" || portal === "team_lead"
          ? [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: "/employees/:depId",
              name: "Employees",
              element: <Employees />,
            },
            {
              path: "/employees",
              name: "Employees",
              element: <Employees />,
            },
            {
              path: "/employee/:empId",
              name: "EmpDetails",
              element: <EmpDetails />,
            },
            {
              path: "/employeeHistory/:empId/:employeeName",
              name: "EmpHistory",
              element: <EmployeeHistory />,
            },
            {
              path: "/department",
              name: "Department",
              element: <Department />,
            },
            {
              path: "/assets",
              name: "Assets",
              element: <Assets />,
            },
            {
              path: "/attendance",
              name: "Attendance",
              element: <Attendance />,
            },
            {
              path: "/leaves",
              name: "Leaves",
              element: <AdminLeaves />,
            },
            {
              path: "/task",
              name: "Task",
              element: <Tasks />,
            },
            {
              path: "/payrolls",
              name: "Payroll",
              element: <Payroll />,
            },
            {
              path: "/recruits",
              name: "Recruits",
              element: <Recruits />,
            },
            {
              path: "/recruits/:recruitId/:recruitName",
              name: "RecruitHistory",
              element: <RecruitHistory />,
            },
            {
              path: "/documents",
              name: "Documents",
              element: <Documents />,
            },
            {
              path: "/announcements",
              name: "Announcements",
              element: <Announcements />,
            },
          ]
          :
          [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: "/employee/:empId",
              name: "EmpDetails",
              element: <EmpDetails />,
            },
            {
              path: "/leaves/emp_leaves",
              name: "EmpLeaves",
              element: <EmpLeaves />,
            },
            {
              path: "/attendance/emp_attendance",
              name: "EmpAttendance",
              element: <EmpAttendance />,
            },
            {
              path: "/task",
              name: "Task",
              element: <Tasks />,
            },
            {
              path: "/payrolls/emp_payrolls",
              name: "EmpPayrol",
              element: <EmpPayrol />,
            },
            {
              path: "/documents",
              name: "Documents",
              element: <Documents />,
            },
          ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

  return (
    <Suspense>
      <ToastContainer />
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
