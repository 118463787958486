import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

let dashboard_data = null;

const initialState = {
  dashboard: "",
};
export const getDashboard = createAsyncThunk(
  "get-dashboard",
  async ({ toast, navigate, portal, setload }, { rejectWithValue }) => {
    try {
      setload(true)
      const response = await api.dashboard(portal);
      dashboard_data = response.data;
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    } finally {
      setload(false);
    }
  }
);
export const teamLeadDashboard = createAsyncThunk(
  "team_lead_dashboard",
  async ({ toast, navigate, setload }, { rejectWithValue }) => {
    try {
      setload(true)
      const response = await api.tl_dashboard();
      dashboard_data = response.data;
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    } finally {
      setload(false)
    }
  }
);

export const filterDashboard = createAsyncThunk(
  "filter-dashboard",
  async ({ data, toast, portal }, { rejectWithValue }) => {
    try {
      const response = await api.filterDashboard(data, portal);
      dashboard_data = response.data;
      if (
        dashboard_data === null ||
        dashboard_data.emp_attendance.length === 0
      ) {
        toast.error("No record Found");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const empDashboard = createAsyncThunk(
  "employee_dashboard",
  async ({ toast, navigate, setload }, { rejectWithValue }) => {
    try {
      setload(true)
      const response = await api.emp_dashboard();
      dashboard_data = response.data;
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    } finally {
      setload(false)
    }
  }
);
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboard: (state) => {
      state.dashboard = initialState.dashboard;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboard.fulfilled, (state) => {
        state.loading = false;
        state.dashboard = dashboard_data;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(filterDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(filterDashboard.fulfilled, (state) => {
        state.loading = false;
        state.dashboard = dashboard_data;
      })
      .addCase(filterDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(empDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(empDashboard.fulfilled, (state) => {
        state.loading = false;
        state.dashboard = dashboard_data;
      })
      .addCase(empDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(teamLeadDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(teamLeadDashboard.fulfilled, (state) => {
        state.loading = false;
        state.dashboard = dashboard_data;
      })
      .addCase(teamLeadDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDashboard } = dashboardSlice.actions
export default dashboardSlice.reducer;
