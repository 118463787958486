import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  assets: [],
  count: 0,
  error: "",
};

export const getAssets = createAsyncThunk(
  "assets",
  async ({ status, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getAssets(status, page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editAssets = createAsyncThunk(
  "edit-assets",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editAssets(uidb64, formValues);
      toast.success("Asset Edited Successfully");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const addAssets = createAsyncThunk(
  "add-assets",
  async ({ formValues, toast, navigate, setEnable, closeEditModal }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addAssets(formValues);
      toast.success("Asset Added Successfully");
      closeEditModal();
      //navigate(0);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteAssets = createAsyncThunk(
  "delete-assets",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteAssets(uidb64);
      toast.success("Asset Deleted Successfully");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.filterChange || action.meta.arg.isInitialLoad) { // to load data from start when pageSize and filter apply
          state.assets = action.payload.results
        }
        else {
          state.assets = state.assets.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAssets.fulfilled, (state, action) => {
        const assets = state.assets
        state.loading = false;
        state.assets = [action.payload, ...assets];
      })
      .addCase(addAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(editAssets.fulfilled, (state, action) => {
        const { id } = action.payload;
        const assets = state.assets.filter((asset) => asset.id !== id);
        state.loading = false;
        state.assets = [action.payload, ...assets];
      })
      .addCase(editAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAssets.fulfilled, (state, action) => {
        state.loading = false;
        const assets = state.assets.filter(
          (asset) => asset.id !== action.payload
        );
        state.assets = [...assets];
      })
      .addCase(deleteAssets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllAssets = (state) => state.assets.assets;
export const selectAssetsLoading = (state) => state.assets.loading;
export const selectAssetsCount = (state) => state.assets.count;
export default assetsSlice.reducer;
