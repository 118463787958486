import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import {
  getEmployee,
  selectEmployee,
  editEmployees,
  getEmployeeHistory,
  selectAllEmployees,
  selectTeamLeadEmployees,
} from "../../redux/features/employeesSlice";
import { BarsLoader } from "../../components/Loaders";
import {
  IoPencilOutline,
  IoCloseOutline,
  IoPencil,
  IoEyeOutline,
  IoChevronBackSharp,
  IoPrintOutline,
} from "react-icons/io5";
import defaultImg from "../../assets/defaultImg.png";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { toast } from "react-toastify";
import { RotatingLinesLoader } from "../../components/Loaders";
import AvatarEditor from "react-avatar-editor";
import {
  AllocatedLeavesModal,
  RemainingLeavesModal,
} from "../../components/Modals";
import "jspdf-autotable";
import { genPdf } from "../pdf/GeneratePdf";

const EmpDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { empId } = useParams();
  const employeeLoading = useSelector((state) => state.employees.loading);
  const employees = useSelector(selectAllEmployees);
  const employee = useSelector(selectEmployee);
  const teamLeadEmployees = useSelector(selectTeamLeadEmployees);

  const {
    portal,
    enable,
    setEnable,
    closeEditModal,
    openAllocatedLeavesModal,
    closeAllocatedLeavesModal,
    openRemainingLeavesModal,
    closeRemainingLeavesModal,
  } = useStateContext();
  const [previewImage, setPreviewImage] = useState(null);
  const [allocatedLeaves, setAllocatedLeaves] = useState();
  const [remainingLeaves, setRemainingLeaves] = useState();
  const [employeeHistory, setEmployeeHistory] = useState([]);

  const fileInputRef = useRef(null);
  const editorRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getEmployee({ toast, emp_id: empId }));
  }, [empId]);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleProfilePicUpload = () => {
    fileInputRef.current.click();
  };

  const handleClose = () => {
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = async () => {
    let formValues = {
      ...employee,
      total_leaves: allocatedLeaves ? allocatedLeaves : employee.total_leaves,
      remaining_leaves: remainingLeaves
        ? remainingLeaves
        : employee.remaining_leaves,
    };
    closeAllocatedLeavesModal();
    closeRemainingLeavesModal();
    let { profile_pic, ...updatedValues } = formValues;

    if (previewImage && editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const resizedImage = canvas.toDataURL("image/jpeg");

      const blob = await fetch(resizedImage).then((res) => res.blob());
      const file = new File([blob], "profile.jpg", { type: "image/jpeg" });
      updatedValues.profile_pic = file;
    }
    updatedValues.department = updatedValues.department_id;
    try {
      const response = await dispatch(
        editEmployees({
          uidb64: empId,
          formValues: updatedValues,
          toast,
          closeEditModal,
          setEnable,
          handleClose,
        })
      ).unwrap();
      setAllocatedLeaves(response.total_leaves);
      setRemainingLeaves(response.remaining_leaves);
    } catch (err) {
      console.error("Failed to update employee", err);
    }
  };

  const handleGeneratePdf = async () => {
    try {
      const historyResponse = await dispatch(
        getEmployeeHistory({ emp_id: empId })
      ).unwrap();
      if (historyResponse && historyResponse.results.length > 0) {
        setEmployeeHistory(historyResponse.results);
        genPdf("download", historyResponse.results, employee);
      } else {
        genPdf("download", [], employee);
      }
    } catch (error) {
      console.error("Error fetching employee history:", error);
    }
  };
  const handleViewPdf = async () => {
    try {
      const historyResponse = await dispatch(
        getEmployeeHistory({ emp_id: empId })
      ).unwrap();
      if (historyResponse && historyResponse.results.length > 0) {
        setEmployeeHistory(historyResponse.results);
        genPdf("view", historyResponse.results, employee);
      } else {
        genPdf("view", [], employee);
      }
    } catch (error) {
      console.error("Error fetching employee history:", error);
    }
  };

  return (
    <>
      {employeeLoading ? (
        <div className="h-[450px] grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <>
          <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-sm">
            <div className="flex py-3 px-4 items-center justify-between border-b-[1px] border-solid border-gray-200">
              <h5 className="mb-0 font-bold text-slate-600">
                <span className="text-emerald-500/90">
                  {employee ? employee.employee_name : ""}
                </span>{" "}
              </h5>
            </div>
            <div className="md:m-4 m-1">
              <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Basic Info
                  </h5>
                </div>
                <div className="p-4 md:p-2">
                  <div className="flex flex-col items-center md:flex-row">
                    <div className="md:w-2/5 2xl:w-1/4">
                      {previewImage ? (
                        <div className="flex items-center flex-col gap-3 w-64">
                          <AvatarEditor
                            ref={editorRef}
                            className="rounded-full h-full w-full m-0 border-none"
                            image={previewImage}
                            color={[255, 255, 255, 0.6]} // RGBA
                            width={256}
                            height={256}
                            scale={1.2}
                            border={10}
                            borderRadius={256}
                          />

                          <div className="flex gap-2">
                            {enable ? (
                              <button
                                onClick={handleSubmit}
                                className=" bg-emerald-500/70 py-1 px-2 rounded-md text-slate-50 hover:bg-emerald-500/90 transition-all duration-300"
                              >
                                Update
                              </button>
                            ) : (
                              <div className="text-slate-50 font-semibold px-4 py-1 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                                <RotatingLinesLoader />
                              </div>
                            )}

                            <button
                              onClick={handleClose}
                              className=" bg-slate-500/70 py-1 px-2 rounded-md text-slate-50 hover:bg-slate-500/90 transition-all duration-300"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="relative rounded-full w-64 h-64">
                          <img
                            src={
                              employee?.profile_pic
                                ? employee?.profile_pic
                                : defaultImg
                            }
                            alt="Profile"
                            className="rounded-full w-full h-full"
                          />
                          <input
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleProfilePicChange}
                          />

                          {portal === "admin" && (
                            <button
                              className="absolute top-5 right-5 h-8 w-8 rounded-full grid place-content-center cursor-pointer bg-emerald-400/90 shadow-xl hover:bg-emerald-400/80 transition-all duration-300"
                              onClick={handleProfilePicUpload}
                            >
                              <IoPencilOutline className="text-slate-50 h-5 w-5" />
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="max-md:mt-4 md:w-3/5 2xl:w-3/4">
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Name:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.employee_name}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Email:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.email}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Phone No:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.phone_number}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Designation:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.designation}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Department:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.department}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Joining Date:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.joining_date}
                        </h5>
                      </div>
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Status:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2">
                          <span
                            className={`p-2 rounded-md text-slate-50 ${
                              employee.employee_status
                                ? employee.employee_status === "WORKING"
                                  ? "bg-emerald-600"
                                  : employee.employee_status === "FIRED"
                                  ? "bg-red-600"
                                  : "bg-slate-600"
                                : ""
                            }`}
                          >
                            {employee.employee_status}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Transaction Info
                  </h5>
                </div>
                <div className="sm:p-4 p-2">
                  <div className="flex md:gap-2 sm:gap-1 flex-col md:flex-row md:justify-around md:mt-3 max-md:items-center">
                    <div className="grid md:grid-cols-2 w-full">
                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Bank:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.bank}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Account Number:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.account_number}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          CNIC:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.national_id_number}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Emergency Contact:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.emergency_contact_number}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Leaves Info
                  </h5>
                </div>

                <div className="sm:p-4 p-2">
                  <div className="flex md:gap-2 sm:gap-1 flex-col md:flex-row md:justify-around md:mt-3 max-md:items-center">
                    <div className="grid md:grid-cols-2 w-full">
                      <div className="flex items-center md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Allocated:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500 flex gap-2">
                          {allocatedLeaves
                            ? allocatedLeaves
                            : employee?.total_leaves}

                          {portal !== "employee" && (
                            <button
                              onClick={() => openAllocatedLeavesModal(true)}
                              className="cursor-pointer p-1 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 "
                            >
                              <IoPencil className="w-4 h-4 text-inherit" />
                            </button>
                          )}
                        </h5>
                      </div>

                      <div className="flex items-center md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Remaining:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500 flex gap-2">
                          {remainingLeaves
                            ? remainingLeaves
                            : employee?.remaining_leaves}
                          {portal !== "employee" && (
                            <button
                              onClick={() => openRemainingLeavesModal(true)}
                              className="cursor-pointer p-1 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 "
                            >
                              <IoPencil className="w-4 h-4 text-inherit" />
                            </button>
                          )}
                        </h5>
                      </div>

                      <p className="font-bold md:col-span-2 text-slate-500 text-xl mt-4 md:ml-4 underline">
                        Leave Allocation Summary:
                      </p>

                      <div className="flex items-start md:px-4 md:gap-4 ml-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Sick:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.leaves
                            ? employee.leaves?.SICK_LEAVE
                              ? employee.leaves?.SICK_LEAVE
                              : "0"
                            : "0"}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Casual:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.leaves
                            ? employee.leaves?.CASUAL_LEAVE
                              ? employee.leaves?.CASUAL_LEAVE
                              : "0"
                            : "0"}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4 ml-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Maternity:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.leaves
                            ? employee.leaves?.MATERNITY_LEAVE
                              ? employee.leaves?.MATERNITY_LEAVE
                              : "0"
                            : "0"}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Paternity:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.leaves
                            ? employee.leaves?.PATERNITY_LEAVE
                              ? employee.leaves?.PATERNITY_LEAVE
                              : "0"
                            : "0"}
                        </h5>
                      </div>

                      <div className="flex items-start md:px-4 md:gap-4 ml-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Marriage:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                          {employee.leaves
                            ? employee.leaves?.MARRIAGE_LEAVE
                              ? employee.leaves?.MARRIAGE_LEAVE
                              : "0"
                            : "0"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Requests Info
                  </h5>
                </div>

                <div className="sm:p-4 p-2">
                  <div className="flex md:gap-2 sm:gap-1 flex-col md:flex-row md:justify-around md:mt-3 max-md:items-center">
                    <div className="grid md:grid-cols-2 w-full">
                      <div className="flex items-center md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Work From Home:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500 flex gap-2">
                          {employee.leaves
                            ? employee.leaves?.WORK_FROM_HOME
                              ? employee.leaves?.WORK_FROM_HOME
                              : "0"
                            : "0"}
                        </h5>
                      </div>
                      <div className="flex items-center md:px-4 md:gap-4">
                        <h5 className="font-bold md:w-1/3 text-slate-600">
                          Extra Days:
                        </h5>
                        <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500 flex gap-2">
                          {employee.leaves
                            ? employee.leaves?.EXTRA_DAYS
                              ? employee.leaves?.EXTRA_DAYS
                              : "0"
                            : "0"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {portal === "admin" && teamLeadEmployees?.length !== 0 ? (
                <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                  <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                    <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                      Associates
                    </h5>
                  </div>

                  <div className="sm:p-4 p-2">
                    <ul className="list-none p-0">
                      {teamLeadEmployees
                        ? teamLeadEmployees.map((employee) => (
                            <li
                              key={employee.id}
                              className="py-2 px-3 border-b border-gray-200 flex items-center justify-between"
                            >
                              <span>{employee.employee_name}</span>
                            </li>
                          ))
                        : "No Team Members"}
                    </ul>
                  </div>
                </div>
              ) : null}
              {portal === "admin" && employee.team_lead_name ? (
                <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                  <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                    <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                      Team Lead
                    </h5>
                  </div>

                  <div className="sm:p-4 p-2">
                    <ul className="list-none p-0">
                      {employee ? (
                        <li className="py-2 px-3 border-b border-gray-200 flex items-center justify-between">
                          <span>{employee.team_lead_name}</span>
                        </li>
                      ) : (
                        "No Team Lead found"
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
            {portal !== "employee" && (
              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <Link
                    to={`/employeeHistory/${empId}/${employee.employee_name}`}
                  >
                    <button className="mt-0 px-2 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300">
                      View History
                    </button>
                  </Link>
                  <div>
                    <button
                      className="cursor-pointer  p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                      onClick={handleGeneratePdf}
                    >
                      <IoPrintOutline className="w-5 h-5 text-inherit" />
                    </button>
                    <button
                      className="cursor-pointer ml-4  p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                      onClick={handleViewPdf}
                    >
                      <IoEyeOutline className="w-5 h-5 text-inherit" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
              <button
                className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
                onClick={() => navigate("/employees")}
              >
                <IoChevronBackSharp />
              </button>
            </div>
          </div>
        </>
      )}
      <AllocatedLeavesModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">
              Update Allocated Leaves
            </h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeAllocatedLeavesModal}
            />
          </div>
          <div className="p-4">
            <label htmlFor="total_leaves" className="block text-slate-600 mb-2">
              Allocated Leaves:
            </label>
            <input
              type="number"
              id="total_leaves"
              name="total_leaves"
              // value={employee?.total_leaves}
              defaultValue={
                allocatedLeaves ? allocatedLeaves : employee?.total_leaves
              }
              onChange={(e) => setAllocatedLeaves(e.target.value)}
              className="w-full border p-2 rounded-md focus:outline-none focus:ring focus:border-emerald-500"
            />
            <button
              onClick={handleSubmit}
              className="mt-4 px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
            >
              Update
            </button>
          </div>
        </div>
      </AllocatedLeavesModal>
      <RemainingLeavesModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">
              Update Remaining Leaves
            </h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeRemainingLeavesModal}
            />
          </div>
          <div className="p-4">
            <label
              htmlFor="remaining_leaves"
              className="block text-slate-600 mb-2"
            >
              Remaining Leaves:
            </label>
            <input
              type="number"
              id="remaining_leaves"
              name="remaining_leaves"
              // value={employee?.remaining_leaves}
              defaultValue={employee?.remaining_leaves}
              onChange={(e) => setRemainingLeaves(e.target.value)}
              className="w-full border p-2 rounded-md focus:outline-none focus:ring focus:border-emerald-500"
            />
            <button
              onClick={handleSubmit}
              className="mt-4 px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
            >
              Update
            </button>
          </div>
        </div>
      </RemainingLeavesModal>
    </>
  );
};

export default EmpDetails;
