import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  historyLoading: false,
  allEmployees: [],
  employee: [],
  allEmployeesNames: [],
  employeeHistory: [],
  teamLeadMembers: [],
  count: 0,
  empHistoryCount: 0,
  error: "",
};
export const getAllEmployees = createAsyncThunk(
  "employees",
  async (
    { employee_status, dept_id, searchData, navigate, page_size, page, portal },
    { rejectWithValue }
  ) => {
    try {
      const department = dept_id;
      const full_name = searchData;
      const params = {
        "employee_status": employee_status,
        "department": department,
        "full_name": full_name,
        "page_size": page_size,
        "page": page,
        "portal": portal
      }
      const response = await api.employee({ params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmployee = createAsyncThunk(
  "employee",
  async ({ toast, emp_id }, { rejectWithValue }) => {
    try {
      const response = await api.getEmployee(emp_id)

      return response.data
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      }
      return rejectWithValue(error.response.data);
    }
  }
)

export const getAllEmployeesNames = createAsyncThunk(
  "employees_names",
  async ({ }, { rejectWithValue }) => {
    try {
      const response = await api.employeeNames()
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteEmployee = createAsyncThunk(
  "delete-employees",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteEmployee(uidb64);
      toast.success("Employee Deleted");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEmployees = createAsyncThunk(
  "add-employees",
  async ({ formValues, toast, navigate, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addEmployee(formValues);
      toast.success("Employee Added");
      navigate(0);
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      const errors = error.response.data;
      if (errors) {
        toast.error(Object.values(errors)[0][0]);
      }
      return rejectWithValue(Object.values(errors)[0]);
    }
  }
);

export const editEmployees = createAsyncThunk(
  "edit-employees",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editEmployee(uidb64, formValues);
      toast.success("Employee Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data.password[0]);
      return rejectWithValue(error.response.data.password[0]);
    }
  }
);

export const getEmployeeHistory = createAsyncThunk(
  "get_employee_history",
  async ({ emp_id, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getEmpHistory(emp_id, page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEmployeeHistory = createAsyncThunk(
  "add-employee_history",
  async ({ formValues, toast, navigate, setEnable }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addEmployeeHistory(formValues);
      toast.success("Employee History Added");
      navigate(0);
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      const errors = error.response.data;
      if (errors) {
        toast.error(Object.values(errors)[0][0]);
      }
      return rejectWithValue(Object.values(errors)[0]);
    }
  }
);

export const editEmployeeHistory = createAsyncThunk(
  "edit-employee-history",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editEmployeeHistory(uidb64, formValues);
      toast.success("Employee History Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data.password[0]);
      return rejectWithValue(error.response.data.password[0]);
    }
  }
);

export const deleteEmployeeHistory = createAsyncThunk(
  "delete-employee-history",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteEmployeeHistory(uidb64);
      toast.success("Employee History Deleted");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);



const employeesSlice = createSlice({
  name: "employees",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (!action.meta.arg.page || action.meta.arg.rowChange
          || action.meta.arg.filterChange || action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad) {// to load data from start when pageSize and filter apply
          state.allEmployees = action.payload.results
        }
        else {
          state.allEmployees = state.allEmployees.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.loading = false;
        const employees = state.allEmployees.filter(
          (employee) => employee.id !== action.payload
        );
        state.allEmployees = [...employees];
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEmployees.fulfilled, (state, action) => {
        const employees = state.allEmployees;
        state.loading = false;
        state.allEmployees = [action.payload, ...employees];
      })
      .addCase(addEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(editEmployees.fulfilled, (state, action) => {

        const { id } = action.payload;
        const employees = state.allEmployees.filter(
          (employee) => employee.id !== id
        );
        state.loading = false;
        state.allEmployees = [action.payload, ...employees];
      })
      .addCase(editEmployees.rejected, (state, action) => {

        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllEmployeesNames.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEmployeesNames.fulfilled, (state, action) => {
        state.loading = false
        state.allEmployeesNames = action.payload.unique_values
      })
      .addCase(getAllEmployeesNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false
        state.employee = action.payload.employee;
        state.teamLeadEmployees = action.payload.employees ? action.payload.employees : null;
      })
      .addCase(getEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEmployeeHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(getEmployeeHistory.fulfilled, (state, action) => {
        state.historyLoading = false
        state.empHistoryCount = action.payload.count
        if (!action.meta.arg.page || action.meta.arg.rowChange || action.meta.arg.isInitialLoad) {// to load data from start when pageSize and filter apply
          state.employeeHistory = action.payload.results
        }
        else {
          state.employeeHistory = state.employeeHistory.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getEmployeeHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(addEmployeeHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(addEmployeeHistory.fulfilled, (state, action) => {
        const employeeHistory = state.allEmployeeHistory;
        state.historyLoading = false;
        state.allEmployeeHistory = [action.payload, ...employeeHistory];
      })
      .addCase(addEmployeeHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(editEmployeeHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(editEmployeeHistory.fulfilled, (state, action) => {
        const { id } = action.payload;
        const employees = state.allEmployeeHistory.filter(
          (employee) => employee.id !== id
        );
        state.historyLoading = false;
        state.allEmployeeHistory = [action.payload, ...employees];
      })
      .addCase(editEmployeeHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteEmployeeHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(deleteEmployeeHistory.fulfilled, (state, action) => {
        state.historyLoading = false;
        const employees = state.employeeHistory.filter(
          (employee) => employee.id !== action.payload
        );
        state.employeeHistory = [...employees];
      })
      .addCase(deleteEmployeeHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
  },
});

export const selectAllEmployees = (state) => state.employees.allEmployees;

export const selectEmployee = (state) => state.employees.employee;
export const selectTeamLeadEmployees = (state) => state.employees.teamLeadEmployees ? state.employees.teamLeadEmployees : null;

export const selectEmployeeHistory = (state) => state.employees.employeeHistory;
export const selectEmployeeById = (state, empId) =>
  state.employees.allEmployees.find((employee) => employee?.id === empId);

export const selectEmployeeCount = (state) =>
  state.employees.count;

export const selectEmployeeHistoryCount = (state) =>
  state.employees.empHistoryCount;

export const selectEmployeeNames = (state) => state.employees.allEmployeesNames

export default employeesSlice.reducer;
