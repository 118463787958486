import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getAllLeaves,
  updateStatus,
  selectAllLeaves,
  selectLeavesLoading,
  deleteLeave,
  selectAllLeavesCount,
} from "../../redux/features/leaveSlice";
import { useNavigate } from "react-router-dom";
import { IoPencil, IoCloseOutline, IoEyeOutline } from "react-icons/io5";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { EditModal, DetailModal, DeleteModal } from "../../components/Modals";
import { useStateContext } from "../../components/contexts/ContextProvider";
import moment from "moment";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const AdminLeaves = () => {
  const navigate = useNavigate();
  const count = useSelector(selectAllLeavesCount);
  const dispatch = useDispatch();
  const leaves = useSelector(selectAllLeaves);
  const leaveLoading = useSelector(selectLeavesLoading);
  const [updatedStatus, setUpdatedStatus] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [errors, setErrors] = useState({});
  const [empId, setEmpId] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");
  const [requestType, setRequestType] = useState("");
  const [formValues, setFormValues] = useState({
    uidb64: "",
  });
  const [isReason, setIsReason] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDetailModal,
    closeDetailModal,
    openDeleteModal,
    closeDeleteModal,
    isToday,
    portal,
  } = useStateContext();

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getAllLeaves({
          emp: empId,
          leave_status: leaveStatus,
          leave_type: requestType,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (leaves.length < count) {
      dispatch(
        getAllLeaves({
          emp: empId,
          leave_status: leaveStatus,
          leave_type: requestType,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      leaves.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAllLeaves({
          emp: empId,
          leave_status: leaveStatus,
          leave_type: requestType,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
          portal,
        })
      );
    } else {
      if (
        (leaves.length < page * perPage &&
          (!leaves.length || leaves.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getAllLeaves({
            emp: empId,
            leave_status: leaveStatus,
            leave_type: requestType,
            page_size: perPage,
            page,
            rowChange,
            filterChange,
            isInitialLoad,
            portal,
          })
        );
      }
    }
  };

  const ClearFilter = () => {
    dispatch(
      getAllLeaves({
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
        portal,
      })
    );
    setEmpId("");
    setLeaveStatus("");
    setRequestType("");
    setIsFilterApplied(false);
  };
  const columns = [
    {
      name: (
        <div className="text-center w-full text-slate-600">Employee Name</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employee_name.charAt(0).toUpperCase() +
            row.employee_name.slice(1)}
        </div>
      ),
      selector: (row) => row.employee_name,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Request Type</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {snakeToTitleCase(row.leave_type)}
        </div>
      ),
      selector: (row) => row.leave_type,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Reason</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setIsReason(row.reason);

              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Request Date</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.request_date)
            ? "Today"
            : moment(row.request_date).format("MMM. DD, YYYY")}
        </div>
      ),
      selector: (row) => row.request_date,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">From</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.from_date)
            ? "Today"
            : moment(row.from_date).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">To</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {" "}
          {isToday(row.to_date)
            ? "Today"
            : moment(row.to_date).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">No. of Days</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.number_of_days}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.status === "REJECTED"
                ? "bg-red-600"
                : row.status === "APPROVED"
                ? "bg-emerald-600"
                : "bg-sky-600"
            }`}
          >
            {snakeToTitleCase(row.status)}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Approved / Rejected By
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.approved_by ? row.approved_by.approved_by_name : null}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Update Status</div>
      ),
      cell: (row) => {
        return (
          <div className="text-center w-full">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                handleEdit(row);
                setDeleteId(row.id);
              }}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      request_date: new Date(row.request_date).toISOString().split("T")[0],
      uidb64: row.id,
    });
    setErrors({});
    openEditModal();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "status") {
      setUpdatedStatus(true);
    }

    if (name === "from_date") {
      // Calculate the difference in days between From Date and To Date
      const fromDate = moment(value);
      const toDate = fromDate
        .clone()
        .add(formValues.number_of_days - 1, "days");
      setDateChange(true);
      setFormValues({
        ...formValues,
        from_date: value,
        to_date: toDate.format("YYYY-MM-DD"),
      });
    } else if (name === "to_date") {
      // Calculate the difference in days between From Date and To Date
      const fromDate = moment(formValues.from_date);
      const toDate = moment(value);
      const numberOfDays = toDate.diff(fromDate, "days") + 1;
      setDateChange(true);
      setFormValues({
        ...formValues,
        [name]: value,
        number_of_days: numberOfDays.toString(),
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (
        formValues.uidb64 !== "" ||
        formValues.uidb64 !== null ||
        formValues.uidb64
      ) {
        if (dateChange && updatedStatus) {
          const { uidb64, status, from_date, to_date } = formValues;
          dispatch(
            updateStatus({
              uidb64,
              formValues: { status, from_date, to_date },
              toast,
              closeEditModal,
              setEnable,
            })
          );
          setDateChange(false);
          setUpdatedStatus(false);
        } else if (updatedStatus) {
          const { uidb64, status } = formValues;
          dispatch(
            updateStatus({
              uidb64,
              formValues: { status },
              toast,
              setEnable,
              closeEditModal,
            })
          );
          setUpdatedStatus(false);
        } else if (dateChange) {
          const { uidb64, from_date, to_date } = formValues;
          dispatch(
            updateStatus({
              uidb64,
              formValues: { from_date, to_date },
              toast,
              closeEditModal,
              setEnable,
            })
          );
          setDateChange(false);
        }
      }
    } catch (err) {
      console.error("Failed to update status", err);
    }
  };

  const filterData = () => {
    setFilterChange(true);
    setIsFilterApplied(true);
  };
  const handleDelete = () => {
    dispatch(
      deleteLeave({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  return (
    <div>
      <DetailModal>
        <div className="min-w-[400px] min-h-[200px] bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600 text-lg">Reason:</h5>
            <IoCloseOutline
              className="w-8 h-8 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeDetailModal}
            />
          </div>
          <div className="p-4">
            <p className="text-slate-500">{isReason}</p>
          </div>
        </div>
      </DetailModal>

      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">Update Status</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Employee Name
                  </label>
                  <input
                    value={formValues.employee_name}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md cursor-not-allowed"
                    disabled
                    type="text"
                    name="employee_name"
                  />
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Request Type
                  </label>
                  <input
                    value={formValues.leave_type?.replace("_", " ")}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md cursor-not-allowed"
                    disabled
                    type="text"
                    name="leave_type"
                  />
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">From</label>
                  <input
                    value={formValues.from_date}
                    disabled={formValues.status !== "PENDING"}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md "
                    type="date"
                    name="from_date"
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">To</label>
                  <input
                    value={formValues.to_date}
                    disabled={formValues.status !== "PENDING"}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md "
                    type="date"
                    name="to_date"
                    min={formValues.from_date}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Requested On
                  </label>
                  <input
                    value={formValues.request_date}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md cursor-not-allowed"
                    disabled
                    type="date"
                    name="request_date"
                    min={formValues.request_date}
                  />
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    No. of Days
                  </label>
                  <input
                    value={formValues.number_of_days}
                    disabled={formValues.status !== "PENDING"}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md "
                    type="text"
                    onChange={handleChange}
                    name="number_of_days"
                  />
                  {errors.number_of_days && (
                    <p className="form-errors">{errors.number_of_days}</p>
                  )}
                </div>

                <div className="md:col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">Reason</label>
                  <textarea
                    value={formValues.reason}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md cursor-not-allowed resize-none"
                    name="reason"
                    rows={3}
                    disabled
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">Status</label>
                  <select
                    value={formValues.status}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="status"
                  >
                    <option value="">Status</option>
                    <option value="PENDING">PENDING</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                  </select>
                  {errors.status && (
                    <p className="form-errors">{errors.status}</p>
                  )}
                </div>
              </div>

              <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex-grow flex justify-between items-center">
                  <div>
                    <button
                      type="button"
                      className={`px-3 py-2 bg-red-500/70 hover:bg-red-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300 ${
                        formValues.status === "APPROVED" ||
                        formValues.status === "REJECTED"
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (formValues.status === "PENDING") {
                          closeEditModal();
                          openDeleteModal();
                        }
                      }}
                      disabled={
                        formValues.status === "APPROVED" ||
                        formValues.status === "REJECTED"
                      }
                    >
                      Delete
                    </button>
                  </div>
                  <div className="flex gap-4 justify-end">
                    {enable ? (
                      <button
                        className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                        type="submit"
                      >
                        Save
                      </button>
                    ) : (
                      <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                        <RotatingLinesLoader />
                      </div>
                    )}
                    <button
                      type="button"
                      className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                      onClick={closeEditModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </EditModal>
      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
            <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
              Requests
            </h5>
            <div className="flex flex-col md:flex-row gap-2 md:items-center max-md:items-start">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Employees"
                  value={empId}
                  onChange={(e) => setEmpId(e.target.value)}
                  className="rounded-lg p-2 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 w-40"
                />
              </div>
              <select
                value={requestType}
                className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                onChange={(e) => setRequestType(e.target.value)}
              >
                <option value="">Select Request Type</option>
                <option value="SICK_LEAVE">Sick Leave</option>
                <option value="CASUAL_LEAVE">Casual Leave</option>
                <option value="EMERGENCY_LEAVE">Emergency Leave</option>
                <option value="MATERNITY_LEAVE">Maternity Leave</option>
                <option value="PATERNITY_LEAVE">Paternity Leave</option>
                <option value="MARRIAGE_LEAVE">Marriage Leave</option>
                <option value="WORK_FROM_HOME">Work From Home</option>
                <option value="EXTRA_DAYS">Extra Days</option>
              </select>
              <select
                value={leaveStatus}
                className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                onChange={(e) => setLeaveStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
              </select>
              <button
                type="button"
                className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                onClick={() => filterData()}
              >
                Apply Filter
              </button>
              {isFilterApplied && (
                <div>
                  <button
                    type="button"
                    className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                    onClick={() => ClearFilter()}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Clear Filter
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="card-body">
            <Pagination
              data={leaves}
              filterChange={filterChange}
              filterUseEffect={filterUseEffect}
              setRowChange={setRowChange}
              rowChangeUseEffect={rowChangeUseEffect}
              pageChangeUseEffect={pageChangeUseEffect}
              isLoading={leaveLoading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLeaves;
