import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

export const login = createAsyncThunk(
  "auth/login",
  async ({ formValues, toast, navigate, setPortal }, { rejectWithValue }) => {
    try {
      const response = await api.signin(formValues);
      const roles = response.data.role;
      let portal;
      if (roles.includes("admin")) {
        portal = "admin";
      } else if (roles.includes("team_lead")) {
        portal = "team_lead";
      } else {
        portal = "employee";
      }
      setPortal(portal);
      localStorage.setItem(
        "profile",
        JSON.stringify({ ...response.data, portal })
      );
      toast.success("Login Successful");
      navigate("/");
      return response.data;
    } catch (error) {
      toast.error("Invalid Credentials, try again");

      return rejectWithValue(error.response.data.detail);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ formValues, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.signup(formValues);
      toast.success("Registration Successful");
      toast.success("Before login, Please activate your account");
      navigate("/login");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.email[0]);
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    { formValues, toast, navigate, show_msg, hide_form },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.resetPassword(formValues);
      show_msg.style.display = "block";
      hide_form.style.display = "none";
      toast.success(`Reset Link Sent Via Email`);
      navigate("/auth/reset_password");

      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);

      return rejectWithValue(error.response.data.email);
    }
  }
);

export const createNewPassword = createAsyncThunk(
  "auth/createPassword",
  async ({ formValues, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.createNewPassword(formValues);
      toast.success(`Password Updated Successfully`);
      navigate("/login");
      return response.data;
    } catch (error) {
      console.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const passwordResetLink = createAsyncThunk(
  "auth/createPasswordLink",
  async ({ uidb64, token }, { rejectWithValue }) => {
    try {
      const response = await api.passwordResetLink({ uidb64, token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    resetLink: {},
    user: null,
    error: "",
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state) => {
      localStorage.removeItem("profile");
      sessionStorage.clear();
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createNewPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(createNewPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(passwordResetLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(passwordResetLink.fulfilled, (state, action) => {
        state.loading = false;
        state.blog = action.payload;
      })
      .addCase(passwordResetLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectUser = (state) => state.auth.user;

export const { setUser, setLogout } = authSlice.actions;
export default authSlice.reducer;
