import React, { Suspense } from "react";
import { useStateContext } from "./contexts/ContextProvider";
import { BarsLoader } from "./Loaders";

const AdminDashboard = React.lazy(() =>
  import("../pages/admin/AdminDashboard")
);
const UserDashboard = React.lazy(() => import("../pages/user/UserDashboard"));
const Dashboard = () => {
  const { portal } = useStateContext();
  if (!portal) {
    return <BarsLoader />;
  }

  return (
    <>
      <Suspense fallback={<BarsLoader />} />
      {portal === "admin" || portal === "team_lead" ? (
        <AdminDashboard portal={portal} />
      ) : (
        <UserDashboard />
      )}
    </>
  );
};

export default Dashboard;
