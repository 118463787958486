import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  recruiters: [],
  count: 0,
  historyLoading: false,
  recruiterHistory: [],
  historyCount: 0,
  error: "",
};

export const addRecruiter = createAsyncThunk(
  "add-recruiter",
  async ({ formValues, toast, navigate, setEnable, closeEditModal }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addRecruiter(formValues);
      toast.success("Application Submitted Successfully");
      //navigate(0);
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRecruiter = createAsyncThunk(
  "recruiter",
  async ({ position, status, applicant_name, page_size, page }, { rejectWithValue }) => {
    try {
      const full_name = applicant_name;
      const response = await api.getRecruiter(position, status, full_name, page_size, page);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteRecruiter = createAsyncThunk(
  "delete-recruiter",
  async (
    { uidb64, toast, setEnable, closeDeleteModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(true);
      await api.deleteRecruiter(uidb64);

      toast.success("Applicant Removed");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const editRecruiter = createAsyncThunk(
  "edit-recruiter",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editRecruiter(uidb64, formValues);
      toast.success("Information Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRecruiterHistory = createAsyncThunk(
  "add-recruiter-history",
  async ({ formValues, toast, navigate, setEnable, closeEditModal }, { rejectWithValue }) => {
    try {
      setEnable(false);
      const response = await api.addRecruiterHistory(formValues);
      toast.success("History Added Successfully");
      //navigate(0);
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRecruiterHistory = createAsyncThunk(
  "recruiter-history",
  async ({ id, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getRecruiterHistory(id, page_size, page);
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteRecruiterHistory = createAsyncThunk(
  "delete-recruiter-history",
  async (
    { uidb64, toast, setEnable, closeDeleteModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(true);
      await api.deleteRecruiterHistory(uidb64);

      toast.success("Applicant History Removed");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);
export const editRecruiterHistory = createAsyncThunk(
  "edit-recruiter-history",
  async (
    { uidb64, formValues, toast, setEnable, closeEditModal },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editRecruiterHistory(uidb64, formValues);
      toast.success("Information Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

const recruiterSlice = createSlice({
  name: "recruiter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRecruiter.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRecruiter.fulfilled, (state, action) => {
        const recruiters = state.recruiters
        state.loading = false;
        state.recruiters = [action.payload, ...recruiters]
      })
      .addCase(addRecruiter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRecruiter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecruiter.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count

        if (action.meta.arg.rowChange || action.meta.arg.filterChange ||
          action.meta.arg.ClearFilter || action.meta.arg.isInitialLoad) { // to load data from start when pageSize and filter apply
          state.recruiters = action.payload.results
        }
        else {
          state.recruiters = state.recruiters.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getRecruiter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRecruiter.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecruiter.fulfilled, (state, action) => {
        state.loading = false;
        const recruiters = state.recruiters.filter(
          (recruiter) => recruiter.id !== action.payload
        );
        state.recruiters = [...recruiters];
      })
      .addCase(deleteRecruiter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editRecruiter.pending, (state) => {
        state.loading = true;
      })
      .addCase(editRecruiter.fulfilled, (state, action) => {
        const { id } = action.payload;
        const recruiters = state.recruiters.filter(
          (recruiter) => recruiter.id !== id
        );
        state.loading = false;
        state.recruiters = [action.payload, ...recruiters];
      })
      .addCase(editRecruiter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addRecruiterHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(addRecruiterHistory.fulfilled, (state, action) => {
        const recruiters = state.recruiterHistory
        state.historyLoading = false;
        state.recruiterHistory = [action.payload, ...recruiters]
      })
      .addCase(addRecruiterHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(getRecruiterHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(getRecruiterHistory.fulfilled, (state, action) => {
        state.historyLoading = false;
        state.historyCount = action.payload.historyCount
        if (action.meta.arg.rowChange || action.meta.arg.filterChange || action.meta.arg.isInitialLoad) { // to load data from start when pageSize and filter apply
          state.recruiterHistory = action.payload.results
        }
        else {
          state.recruiterHistory = state.recruiterHistory.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getRecruiterHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteRecruiterHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(deleteRecruiterHistory.fulfilled, (state, action) => {
        state.historyLoading = false;
        const recruiters = state.recruiterHistory.filter(
          (recruiter) => recruiter.id !== action.payload
        );
        state.recruiterHistory = [...recruiters];
      })
      .addCase(deleteRecruiterHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      })
      .addCase(editRecruiterHistory.pending, (state) => {
        state.historyLoading = true;
      })
      .addCase(editRecruiterHistory.fulfilled, (state, action) => {
        const { id } = action.payload;
        const recruiters = state.recruiterHistory.filter(
          (recruiter) => recruiter.id !== id
        );
        state.historyLoading = false;
        state.recruiterHistory = [action.payload, ...recruiters];
      })
      .addCase(editRecruiterHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllRecruits = (state) => state.recruiter.recruiters;
export const selectRecruitsLoading = (state) => state.recruiter.loading;

export const selectRecruitsCount = (state) => state.recruiter.count;

export const selectAllRecruitHistory = (state) => state.recruiter.recruiterHistory;

export const selectRecruitHistoryCount = (state) => state.recruiter.historyCount;

export default recruiterSlice.reducer;
