import React, { useEffect, useState } from "react";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  getAnnouncements,
  addAnnouncements,
  selectAllAnnouncements,
  editAnnouncements,
  selectAnnouncementLoading,
  selectAnnouncementsCount,
  deleteAnnouncements,
} from "../../redux/features/announcementSlice";
import {
  IoTrashOutline,
  IoCloseOutline,
  IoEyeOutline,
  IoPencil,
} from "react-icons/io5";
import { EditModal, DeleteModal, DetailModal } from "../../components/Modals";
import { announcementSchema } from "../../validation-schemas/schemas";
import moment from "moment";
import Pagination from "../../components/Pagination";
import * as Yup from "yup";

const Announcements = () => {
  const {
    portal,
    openDeleteModal,
    closeDeleteModal,
    enable,
    setEnable,
    isToday,
    openEditModal,
    openDetailModal,
    closeEditModal,
    closeDetailModal,
    profile,
  } = useStateContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    detail: "",
    date_from: null,
    date_to: null,
    added_by: profile?.id,
    uidb64: "",
  };

  const announcements = useSelector(selectAllAnnouncements);
  const ann_Loader = useSelector(selectAnnouncementLoading);
  const count = useSelector(selectAnnouncementsCount);
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Announcement");
  const [deleteId, setDeleteId] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [rowChange, setRowChange] = useState(false);
  const [detail, setDetail] = useState("");

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const rowChangeUseEffect = () => {
    if (announcements.length < count) {
      dispatch(
        getAnnouncements({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      announcements.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAnnouncements({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (announcements.length < page * perPage &&
          (!announcements.length || announcements.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getAnnouncements({
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Title</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.title}</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Detail</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setDetail(row.detail);
              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">From</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.date_from
            ? isToday(row.date_from)
              ? "Today"
              : moment(row.date_from).format("MMM. DD, YYYY")
            : "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">To</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.date_to
            ? isToday(row.date_to)
              ? "Today"
              : moment(row.date_to).format("MMM. DD, YYYY")
            : "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Added By</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.added_by ? row.added_by.added_by_name : ""}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Added Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.added_date)
            ? "Today"
            : moment(row.added_date).format("MMM. DD, YYYY")}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Announcement");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      added_by: row.added_by.added_by_id,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Announcement");
    openEditModal();
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "date_to")
      setFormValues({ ...formValues, date_to: value === "" ? null : value });
    else if (name === "date_from")
      setFormValues({ ...formValues, date_from: value === "" ? null : value });
    else setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        try {
          await announcementSchema.validate(formValues, { abortEarly: false });
          dispatch(
            addAnnouncements({
              formValues: formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add Announcement", err);
      }
    } else {
      try {
        try {
          await announcementSchema.validate(formValues, { abortEarly: false });
          const { uidb64 } = formValues;
          dispatch(
            editAnnouncements({
              uidb64,
              formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update announcement", err);
      }
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteAnnouncements({
        uidb64: deleteId,
        toast,
        closeDeleteModal,
        setEnable,
      })
    );
  };

  return (
    <>
      <DeleteModal handleDelete={handleDelete} />
      <div>
        <DetailModal>
          <div className="min-w-[400px] min-h-[200px] bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
            <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
              <h5 className="mb-0 font-bold text-slate-600 text-lg">Detail:</h5>
              <IoCloseOutline
                className="w-8 h-8 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                onClick={closeDetailModal}
              />
            </div>
            <div className="p-4">
              <p className="text-slate-500">{detail}</p>
            </div>
          </div>
        </DetailModal>

        <EditModal>
          <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
            <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
              <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
              <IoCloseOutline
                className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                onClick={() => closeEditModal()}
              />
            </div>
            <div className="m-1">
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-3 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">Title</label>
                    <input
                      value={formValues.title}
                      onChange={handleChange}
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      name="title"
                    />

                    {errors.title && (
                      <p className="form-errors">{errors.title}</p>
                    )}
                  </div>
                  <div>
                    <label className="ml-1 mb-1 text-slate-600">From</label>
                    <input
                      value={
                        formValues.date_from === null
                          ? ""
                          : formValues.date_from
                      }
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      type="date"
                      onChange={handleChange}
                      name="date_from"
                    />
                    {errors.date_from && (
                      <p className="form-errors">{errors.date_from}</p>
                    )}
                  </div>

                  <div>
                    <label className="ml-1 mb-1 text-slate-600">To</label>
                    <input
                      value={
                        formValues.date_to === null ? "" : formValues.date_to
                      }
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                      type="date"
                      onChange={handleChange}
                      name="date_to"
                      min={formValues.date_from}
                    />
                    {errors.date_to && (
                      <p className="form-errors">{errors.date_to}</p>
                    )}
                  </div>
                  <div className="md:col-span-3">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">Detail</label>
                      <textarea
                        value={formValues.detail}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md resize-none"
                        rows="5"
                        name="detail"
                      />
                      {errors.detail && (
                        <p className="form-errors">{errors.detail}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                  <div className="flex gap-4">
                    {enable ? (
                      <button
                        className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                        type="submit"
                      >
                        Save
                      </button>
                    ) : (
                      <div className="text-slate-50 font-semibold px-4 py-[9px] bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                        <RotatingLinesLoader />
                      </div>
                    )}
                    <button
                      type="button"
                      className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                      onClick={() => closeEditModal()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </EditModal>

        <div className="flex flex-col justify-between lg:flex-row items-center mx-3 rounded-md">
          <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full rounded-md">
            <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between rounded-t-md">
              <h5
                className={`md:mb-0 max-md:mb-2 font-bold text-slate-600 ${
                  portal !== "employee" ? "" : "py-2"
                }`}
              >
                Announcements
              </h5>
              {portal !== "employee" ? (
                <button
                  onClick={handleNew}
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                >
                  Add Announcement
                </button>
              ) : null}
            </div>
            <div className="card-body">
              <Pagination
                data={announcements}
                setRowChange={setRowChange}
                rowChangeUseEffect={rowChangeUseEffect}
                pageChangeUseEffect={pageChangeUseEffect}
                isLoading={ann_Loader}
                count={count}
                columns={columns}
                page={page}
                perPage={perPage}
                setPerPage={setPerPage}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcements;
