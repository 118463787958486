import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";
import {getUsersAttendance} from "./AdminAttendanceSlice";

const initialState = {
  loading: false,
  policies: [],
  count: 0,
  error: "",
};
export const getPolicies = createAsyncThunk(
  "policies",
  async ({ toast, navigate, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getPolicies(page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePolicy = createAsyncThunk(
  "delete-policy",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deletePolicy(uidb64);
      toast.success("Policy Deleted Successfully");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editPolicy = createAsyncThunk(
  "edit-policy",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editPolicy(uidb64, formValues);
      toast.success("Policy Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPolicy = createAsyncThunk(
  "add-policy",
  async (
    { formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.addPolicy(formValues);
      toast.success("Policy Added");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
const policiesSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPolicies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (action.meta.arg.rowChange|| action.meta.arg.isInitialLoad){
            state.policies = action.payload.results
        }
        else
        {
          state.policies = state.policies.concat(action.payload.results) // concat the data in previous for pagination
        }
      })
      .addCase(getUsersAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePolicy.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePolicy.fulfilled, (state, action) => {
        state.loading = false;
        const policies = state.policies.filter(
          (policy) => policy.id !== action.payload
        );
        state.policies = [...policies];
      })
      .addCase(deletePolicy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addPolicy.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPolicy.fulfilled, (state, action) => {
        const policies = state.policies;
        state.loading = false;
        state.policies = [action.payload, ...policies];
      })
      .addCase(addPolicy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editPolicy.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPolicy.fulfilled, (state, action) => {
        const { id } = action.payload;
        const policies = state.policies.filter((policy) => policy.id !== id);
        state.loading = false;
        state.policies = [action.payload, ...policies];
      })
      .addCase(editPolicy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectAllPolicies = (state) => state.policy.policies;
export const selectPolicyLoading = (state) => state.policy.loading;

export const selectPolicyCount = (state) => state.policy.count

export default policiesSlice.reducer;
