import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/logo.png";
import moment from "moment";

const formatDate = (dateString) => {
  return moment(dateString).format("YYYY-MM-DD");
};

const genPdf = (type, employeeHistory, employee) => {
  // var jspdf = new jsPDF("a4");
  const jspdf = new jsPDF("p", "mm", "a4");

  const logoImage = new Image();
  logoImage.src = logo;

  jspdf.addImage(logoImage, "png", 20, 25, 30, 5);
  jspdf.setFont("times", "normal");
  jspdf.setFontSize(10);
  jspdf.text("3rd Floor 48-49 Block A", 155, 25);
  jspdf.text("Revenue Housing Society", 155, 30);
  jspdf.text("Johar Town  Lahore, Punjab", 155, 35);

  jspdf.setFontSize(20);
  jspdf.text("Employee Details", 100, 45, "center");

  jspdf.setFont("times", "normal");
  jspdf.setFontSize(10);

  jspdf.text("Name:", 25, 55);
  jspdf.text(employee?.first_name + " " + employee.last_name, 140, 55);

  jspdf.text("Email:", 25, 65);
  jspdf.text(employee?.email, 140, 65);

  jspdf.text("Designation:", 25, 75);
  jspdf.text(employee?.designation, 140, 75);

  jspdf.text("Department:", 25, 85);
  jspdf.text(employee?.department, 140, 85);

  jspdf.text("Joining Date:", 25, 95);
  jspdf.text(employee?.joining_date, 140, 95);

  jspdf.text("Status:", 25, 105);
  jspdf.text(employee?.employee_status, 140, 105);

  jspdf.setFont("times", "bold");
  jspdf.setFontSize(15);
  jspdf.text("Transaction Info", 25, 115);

  jspdf.setFont("times", "normal");
  jspdf.setFontSize(10);

  jspdf.text("Bank:", 25, 125);
  jspdf.text(employee?.bank, 140, 125);

  jspdf.text("Account Number:", 25, 135);
  jspdf.text(employee?.account_number, 140, 135);

  jspdf.text("CNIC:", 25, 145);
  jspdf.text(employee?.national_id_number, 140, 145);

  jspdf.text("Emergency Contact:", 25, 155);
  jspdf.text(employee?.emergency_contact_number, 140, 155);

  const historyHeaders = [
    { title: "Subject", dataKey: "subject" },
    { title: "Remarks", dataKey: "remarks" },
    { title: "Increment", dataKey: "increment" },
    { title: "Interval", dataKey: "interval" },
    { title: "Review By", dataKey: "review_by" },
    { title: "Added By", dataKey: "added_by" },
  ];
  const tableStyles = {
    font: "times",
    fontStyle: "normal",
    fontSize: 10,
  };

  if (employeeHistory.length > 0) {
    const historyRows = employeeHistory.map((record) => ({
      subject: record.subject,
      remarks: record.remarks,
      increment: record.increment,
      interval: `${record.interval_from} to ${record.interval_to}`,
      review_by: `${record.review_by.review_by_name} on ${record.review_date}`,
      added_by: `${record.added_by.added_by_name} on ${formatDate(
        record.added_date
      )}`,
    }));

    jspdf.autoTable({
      head: [historyHeaders.map((header) => header.title)],
      body: historyRows.map((row) => Object.values(row)),
      startY: 170,
      theme: "grid",
      styles: tableStyles,
      margin: { top: 10 },
    });
  } else {
    jspdf.setFont("times", "normal");
    jspdf.setFontSize(14);
    jspdf.text("No Employee History available.", 25, 170);
  }

  if (type === "download") {
    jspdf.save("employee_details");
  } else {
    var pdfUrl = jspdf.output("bloburl");
    window.open(pdfUrl);
  }
};

export { genPdf };
