import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  loading: false,
  departments: [],
  departmentNames: [],
  count:0,
  error: "",
};
export const getDepartment = createAsyncThunk(
  "department",
  async ({ toast, navigate, page_size, page}, { rejectWithValue }) => {
    try {
      const response = await api.getDepartment(page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentNames = createAsyncThunk(
  "department-names",
  async ( { rejectWithValue }) => {
    try {
      const response = await api.departmentNames();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "delete-department",
  async (
    { uidb64, toast, closeDeleteModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      await api.deleteDepartment(uidb64);
      toast.success("Department Deleted Successfully");
      closeDeleteModal();
      setEnable(true);
      return uidb64;
    } catch (error) {
      setEnable(true);
      return rejectWithValue(error.response.data);
    }
  }
);

export const editDepartment = createAsyncThunk(
  "edit-department",
  async (
    { uidb64, formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.editDepartment(uidb64, formValues);
      toast.success("Department Updated");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addDepartment = createAsyncThunk(
  "add-department",
  async (
    { formValues, toast, closeEditModal, setEnable },
    { rejectWithValue }
  ) => {
    try {
      setEnable(false);
      const response = await api.addDepartment(formValues);
      toast.success("Department Added");
      closeEditModal();
      setEnable(true);
      return response.data;
    } catch (error) {
      setEnable(true);
      toast.error(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
const departmentSlice = createSlice({
  name: "department",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (action.meta.arg.rowChange || action.meta.arg.isInitialLoad){ // to load data from start when pageSize apply
            state.departments = action.payload.results
        }
        else
        {
          state.departments = state.departments.concat(action.payload.results)// concat the data in previous for pagination
        }
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.loading = false;
        const departments = state.departments.filter(
          (department) => department.id !== action.payload
        );
        state.departments = [...departments];
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        const departments = state.departments;
        state.loading = false;
        state.departments = [action.payload, ...departments];
      })
      .addCase(addDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(editDepartment.fulfilled, (state, action) => {
        const { id } = action.payload;
        const departments = state.departments.filter(
          (department) => department.id !== id
        );
        state.loading = false;
        state.departments = [action.payload, ...departments];
      })
      .addCase(editDepartment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDepartmentNames.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartmentNames.fulfilled, (state, action) => {
        state.loading = false;
        state.departmentNames = action.payload.unique_values
      })
      .addCase(getDepartmentNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const selectAllDepartments = (state) => state.department.departments;
export const selectDepartmentLoading = (state) => state.department.loading;

export const selectDepartmentCount = (state) =>
  state.department.count;

export const selectDepartmentNames = (state) =>
  state.department.departmentNames;

export default departmentSlice.reducer;
