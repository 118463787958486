import { useStateContext } from "./contexts/ContextProvider";
import { RotatingLinesLoader } from "./Loaders";
import { IoCloseOutline } from "react-icons/io5";

export const EditModal = ({ children }) => {
  const { showEditModal, closeEditModal } = useStateContext();

  const handleModalClick = (e) => {
    // e.stopPropagation();
    closeEditModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-40 transition-opacity duration-300 ${
        showEditModal ? "bg-gray-950/40" : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showEditModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  );
};

export const DeleteModal = (props) => {
  const { showDeleteModal, closeDeleteModal, enable } = useStateContext();

  const handleModalClick = (e) => {
    closeDeleteModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-50 transition-opacity duration-300 ${
        showDeleteModal ? "bg-gray-950/40" : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showDeleteModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        <div className="min-w-0 bg-white bg-clip-border w-full py-8 px-14 rounded-md relative">
          <div className="flex flex-col items-center mt-3">
            <p>Are you sure you want to delete this?</p>
            <div className="flex gap-2">
              {enable ? (
                <button
                  onClick={props.handleDelete}
                  className=" bg-emerald-500/70 py-2 px-3 rounded-md text-slate-50 hover:bg-emerald-500/90 transition-all duration-300"
                >
                  Delete
                </button>
              ) : (
                <div className="text-slate-50 font-semibold px-4 py-[9px] bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                  <RotatingLinesLoader />
                </div>
              )}

              <button
                onClick={() => closeDeleteModal()}
                className=" bg-slate-500/70 py-2 px-3 rounded-md text-slate-50 hover:bg-slate-500/90 transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
          <IoCloseOutline
            className="w-8 h-8 text-slate-500 cursor-pointer absolute top-3 right-3"
            onClick={() => closeDeleteModal()}
          />
        </div>
      </div>
    </div>
  );
};

export const ImportModal = ({ children }) => {
  const { showImportModal, closeImportModal } = useStateContext();

  const handleModalClick = (e) => {
    // e.stopPropagation();
    closeImportModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-40 transition-opacity duration-300 ${
        showImportModal ? "bg-gray-950/40" : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showImportModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  );
};

export const DetailModal = ({ children }) => {
  const { showDetailModal, closeDetailModal } = useStateContext();

  const handleModalClick = (e) => {
    // e.stopPropagation();
    closeDetailModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-40 transition-opacity duration-300 ${
        showDetailModal ? "bg-gray-950/40" : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showDetailModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  );
};
export const AllocatedLeavesModal = ({ children }) => {
  const { showAllocatedLeavesModal, closeAllocatedLeavesModal } =
    useStateContext();

  const handleModalClick = (e) => {
    // e.stopPropagation();
    closeAllocatedLeavesModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-40 transition-opacity duration-300 ${
        showAllocatedLeavesModal
          ? "bg-gray-950/40"
          : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showAllocatedLeavesModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  );
};
export const RemainingLeavesModal = ({ children }) => {
  const { showRemainingLeavesModal, closeRemainingLeavesModal } =
    useStateContext();

  const handleModalClick = (e) => {
    // e.stopPropagation();
    closeRemainingLeavesModal();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center overflow-y-auto w-ful z-40 transition-opacity duration-300 ${
        showRemainingLeavesModal
          ? "bg-gray-950/40"
          : "opacity-0 pointer-events-none"
      }`}
      onClick={handleModalClick}
      style={{ transitionDelay: showRemainingLeavesModal ? "0.3s" : "0s" }}
    >
      <div
        className="my-auto mb-8 max-w-4xl max-lg:max-w-2xl max-md:max-w-lg max-sm:max-w-sm left-0 transition-all duration-300"
        onClick={handleContentClick}
      >
        {children}
      </div>
    </div>
  );
};
