import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  getDepartment,
  deleteDepartment,
  editDepartment,
  addDepartment,
  selectDepartmentCount,
  selectAllDepartments,
  selectDepartmentLoading,
} from "../../redux/features/departmentSlice";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  IoPencil,
  IoTrashOutline,
  IoCloseOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { departmentSchema } from "../../validation-schemas/schemas";
import { EditModal, DeleteModal } from "../../components/Modals";

const Department = () => {
  const department = useSelector(selectAllDepartments);
  const departmentloader = useSelector(selectDepartmentLoading);
  const count = useSelector(selectDepartmentCount);
  let uidb64 = "";

  const initialValues = {
    department_name: "",
    description: "",
    uidb64,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Department");
  const [deleteId, setDeleteId] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [rowChange, setRowChange] = useState(false);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
  } = useStateContext();

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const rowChangeUseEffect = () => {
    if (department.length < count) {
      dispatch(
        getDepartment({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      department.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getDepartment({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (department.length < page * perPage &&
          (!department.length || department.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getDepartment({
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.department_name.charAt(0).toUpperCase() +
            row.department_name.slice(1)}
        </div>
      ),
      selector: (row) => row.department_name,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Description</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.description.charAt(0).toUpperCase() + row.description.slice(1)}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">No. Employees</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employees_count}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Employees</div>,
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <NavLink
            to={`/employees/${row.id}`}
            state={{ row }}
            className="cursor-pointer w-10 py-2 flex items-center justify-center bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
          >
            <IoEyeOutline className="w-5 h-5 text-inherit text-slate-50" />
          </NavLink>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="w-full flex justify-center gap-2">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Department");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Department");
    openEditModal();
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      try {
        await departmentSchema.validate(formValues, { abortEarly: false });
        const { uidb64 } = formValues;
        if (uidb64 === "" || uidb64 === null || uidb64 === undefined) {
          dispatch(
            addDepartment({ formValues, toast, closeEditModal, setEnable })
          );
        } else {
          dispatch(
            editDepartment({
              uidb64,
              formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        }
      } catch (validationErrors) {
        const errors = {};
        if (validationErrors) {
          validationErrors.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        }
        setErrors(errors);
      }
    } catch (err) {
      console.error("Failed to add employee", err);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteDepartment({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  return (
    <div>
      {departmentloader ? (
        <div className="dashboard-min-height grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <div>
          <EditModal>
            <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
                <IoCloseOutline
                  className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={closeEditModal}
                />
              </div>
              <div className="m-1">
                <form onSubmit={handleSubmit} className="md:w-[500px]">
                  <div className="grid gap-3 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Department Name
                      </label>
                      <input
                        value={formValues.department_name}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="text"
                        name="department_name"
                      />
                      {errors.department_name && (
                        <p className="form-errors">{errors.department_name}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Description
                      </label>
                      <textarea
                        value={formValues.description}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md resize-none"
                        rows="3"
                        name="description"
                      />
                      {errors.description && (
                        <p className="form-errors">{errors.description}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                    <div className="flex gap-4">
                      {enable ? (
                        <button
                          className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                          type="submit"
                        >
                          Save
                        </button>
                      ) : (
                        <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                          <RotatingLinesLoader />
                        </div>
                      )}
                      <button
                        type="button"
                        className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        onClick={closeEditModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </EditModal>

          <DeleteModal handleDelete={handleDelete} />

          <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
            <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
              <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
                <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
                  Departments
                </h5>
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={handleNew}
                >
                  Add Department
                </button>
              </div>
              <div className="card-body">
                <Pagination
                  data={department}
                  setRowChange={setRowChange}
                  rowChangeUseEffect={rowChangeUseEffect}
                  pageChangeUseEffect={pageChangeUseEffect}
                  attendanceLoading={departmentloader}
                  count={count}
                  columns={columns}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Department;
