import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './features/authSlice';
import MarkAttendanceReducer from './features/attendanceSlice';
import employeesReducer from './features/employeesSlice';
import departmentReducer from './features/departmentSlice';
import AdminAttendanceReducer from './features/AdminAttendanceSlice';
import recruiterReducer from './features/recruiterSlice';
import assetsReducer from './features/assetsSlice';
import dashboardReducer from './features/dashboardSlice';
import leaveReducer from './features/leaveSlice';
import payrollReducer from './features/payrollSlice';
import policiesReducer from './features/policiesSlice';
import taskReducer from './features/tasksSlice'
import announcementReducer from './features/announcementSlice'

export default configureStore({
  reducer: {
    auth: AuthReducer,
    attendance: MarkAttendanceReducer,
    employees: employeesReducer,
    department: departmentReducer,
    adminAttendance: AdminAttendanceReducer,
    recruiter: recruiterReducer,
    assets: assetsReducer,
    dashboard: dashboardReducer,
    leave: leaveReducer,
    payroll: payrollReducer,
    policy: policiesReducer,
    task: taskReducer,
    announcement: announcementReducer
  },
});
