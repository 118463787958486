import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initialState = {
  attendance: [],
  user_all_attendance: [],
  count: 0,
  checkinAt: null,
  checkoutAt: null,
  error: "",
  loading: false,
};

export const getAllAttendance = createAsyncThunk(
  "get_attendance",
  async ({ toast, navigate, page_size, page }, { rejectWithValue }) => {
    try {
      const response = await api.getAllAttendance(page_size, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAttendance = createAsyncThunk(
  "get_today_attendance",
  async ({ toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.getAttendance();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAttendance = createAsyncThunk(
  "attendance/markAttendance",
  async (
    { formValues, toast, handleReset, handleStart },
    { rejectWithValue }
  ) => {
    try {
      const pause = localStorage.getItem("pauseTimer");
      if (formValues.action === "check-out" && pause && pause !== "true") {
        toast.error("Please pause the timer first!");
        return rejectWithValue({ error: "Please pause the timer first!" });
      }
      const response = await api.markAttendance(formValues);

      response.data.success
        ? toast.success(`${response.data.success}`)
        : toast.error(`${response.data.detail}`);

      return response.data;
    } catch (error) {
      toast.error(error.response.data.detail);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.attendance = action.payload !== "" ? action.payload : [];
      })
      .addCase(getAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.count
        if (!action.meta.arg.page || action.meta.arg.rowChange || action.meta.arg.isInitialLoad) { // to load data from start when pageSize  apply
          state.user_all_attendance = action.payload !== "" ? action.payload.results : []
        }
        else {
          state.user_all_attendance = state.user_all_attendance.concat(action.payload !== "" ? action.payload.results : []) // concat the data in previous for pagination
        }
      })
      .addCase(getAllAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(markAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(markAttendance.fulfilled, (state, action) => {
        const date = new Date();
        const [hours, minutes, seconds] = [
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ];

        state.loading = false;

        if (action.payload.success === "Employee checked-in successfully!") {
          state.checkinAt = `${hours}:${minutes}:${seconds}`;
          localStorage.setItem(
            "timer",
            JSON.stringify({ seconds: 0, minutes: 0, hours: 0 })
          );
          localStorage.setItem("pauseTimer", "false");
        } else if (
          action.payload.success === "Employee checked-out successfully!"
        ) {
          state.checkoutAt = `${hours}:${minutes}:${seconds}`;
        } else if (action.payload.success === "Timer paused successfully!") {
          localStorage.setItem("pauseTimer", "true");
        } else if (action.payload.success === "Timer resumed successfully!") {
          localStorage.setItem("pauseTimer", "false");
          const timer = action.payload.timer_value;
          localStorage.setItem("timer", timer.toString());
        }
      })
      .addCase(markAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectUserAttendanceLoading = (state) => state.attendance.loading;

export const selectAllUserAttendance = (state) =>
  state.attendance.user_all_attendance;

export const selectTodayAttendance = (state) =>
  state.attendance.attendance;
export const selectAttendanceCount = (state) =>
  state.attendance.count;

export default attendanceSlice.reducer;
