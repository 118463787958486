import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUsersAttendance,
  selectAttendanceCount,
  selectAllUserAttendance,
  selectAttendanceLoading,
} from "../../redux/features/AdminAttendanceSlice";
import Pagination from "../../components/Pagination";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { useStateContext } from "../../components/contexts/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BarsLoader } from "../../components/Loaders";

const Attendance = () => {
  const attendance = useSelector(selectAllUserAttendance);
  const attendanceLoading = useSelector(selectAttendanceLoading);
  const count = useSelector(selectAttendanceCount);
  const [filterDate, setFilterDate] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isToday, portal } = useStateContext();

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const columns = [
    {
      name: (
        <div className="text-center w-full text-slate-600">Employee Name</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employee_name}
        </div>
      ),
      selector: (row) => row.employee_name,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {isToday(row.check_in_date)
            ? "Today"
            : moment(row.check_in_date).format("MMM. DD, YYYY")}
        </div>
      ),
      selector: (row) => row.check_in_date,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Check In At</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {formatTime(row.time_check_in)}
        </div>
      ),
      selector: (row) => row.time_check_in,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Check Out At</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {formatTime(row.time_check_out)}
        </div>
      ),
      selector: (row) => row.time_check_out,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Total Hours Worked
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.total_time ? row.total_time : "No Checkout Time"}
        </div>
      ),
    },
  ];

  const [startDate, setStartDate] = useState(new Date());

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getUsersAttendance({
          emp_id: searchData,
          date: filterDate,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (attendance.length < count) {
      dispatch(
        getUsersAttendance({
          emp_id: searchData,
          date: filterDate,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
      setRowChange(false);
    }
  };

  const pageChangeUseEffect = () => {
    if (
      attendance.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getUsersAttendance({
          emp_id: searchData,
          date: filterDate,
          page_size: perPage * page,
          page: 1,
          portal,
          rowChange: true, //there is no change in row in this case...this is because  when user jump in the last page then all data loads in slice
        })
      );
    } else {
      if (
        (attendance.length < page * perPage &&
          (!attendance.length || attendance.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getUsersAttendance({
            emp_id: searchData,
            date: filterDate,
            page_size: perPage,
            page,
            portal,
            isInitialLoad,
          })
        );
      }
    }
  };

  const ClearFilter = () => {
    dispatch(
      getUsersAttendance({
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
        portal,
      })
    );
    setSearchQuery("");
    setSearchData("");
    setFilterDate("");
    setStartDate(new Date());
    setIsFilterApplied(false);
  };

  const filterData = () => {
    const formatString = "YYYY-MM-DD";
    const date = startDate ? moment(startDate).format(formatString) : "";
    setFilterDate(date);
    setSearchData(searchQuery);
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) {
      return "";
    }
    const format = "hh:mm A";
    return moment(time, "HH:mm:ss").format(format);
  };

  return (
    <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
      <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
        <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between">
          <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
            Attendance
          </h5>
          <div className="flex flex-col md:flex-row gap-1 items-start md:items-center">
            <div className="flex md:flex-row gap-2 md:items-center max-md:items-start flex-wrap">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Employees"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 w-40"
                />
              </div>
              <div className="md:me-1">
                <DatePicker
                  id="date"
                  placeholderText="Select Date"
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 w-36"
                  autoComplete="off"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
            <button
              type="button"
              className="cursor-pointer px-3 py-2.5 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
              onClick={() => filterData()}
            >
              Apply Filter
            </button>
            {isFilterApplied && (
              <div>
                <button
                  type="button"
                  className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                  onClick={() => ClearFilter()}
                >
                  {" "}
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />
                  Clear Filter
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <Pagination
            data={attendance}
            filterChange={filterChange}
            filterUseEffect={filterUseEffect}
            setRowChange={setRowChange}
            rowChangeUseEffect={rowChangeUseEffect}
            pageChangeUseEffect={pageChangeUseEffect}
            isLoading={attendanceLoading}
            count={count}
            columns={columns}
            page={page}
            perPage={perPage}
            setPerPage={setPerPage}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Attendance;
