import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  IoPencil,
  IoTrashOutline,
  IoTrendingUpOutline,
  IoTrendingDownOutline,
  IoCloseOutline,
  IoPaperPlaneOutline,
  IoEyeOutline,
} from "react-icons/io5";
import {
  getPayroll,
  addPayroll,
  editPayroll,
  deletePayroll,
  selectPayrolls,
  selectPayrollLoading,
  selectPayrollAllCount,
  importPayroll,
  emailPayroll,
} from "../../redux/features/payrollSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  payrollSchema,
  importPayrollSchema,
} from "../../validation-schemas/schemas";
import {
  selectEmployeeNames,
  getAllEmployeesNames,
} from "../../redux/features/employeesSlice";
import {
  EditModal,
  DeleteModal,
  ImportModal,
  DetailModal,
} from "../../components/Modals";
import Pagination from "../../components/Pagination";

const Payroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payroll = useSelector(selectPayrolls);
  const employees = useSelector(selectEmployeeNames);
  const payrollLoading = useSelector(selectPayrollLoading);
  const count = useSelector(selectPayrollAllCount);
  const [startDate, setStartDate] = useState(new Date());
  const payrollYear = startDate.getFullYear();

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);

  const [filterEmp, setFilterEmp] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Payroll");
  const [deleteId, setDeleteId] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    openImportModal,
    closeImportModal,
    openDetailModal,
    closeDetailModal,
  } = useStateContext();

  const initialValues = {
    employee: "",
    basic_salary: 0,
    bonus: 0,
    reimbursement: 0,
    tax_deduction: 0,
    travel_allowance: 0,
    config: {
      arrears: 0.0,
      overtime: 0.0,
      allowance: 0.0,
      increment: 0.0,
      deductions: 0.0,
      loan_advance: 0.0,
      total_salary: 0.0,
      project_bonus: 0.0,
      tax_deductions: 0.0,
      late_sitting_bonus: 0.0,
      project_commission: 0.0,
    },
    month: "",
    year: payrollYear,
    released: false,
    uidb64: "",
  };

  const payrollSheetlValues = {
    month: "",
    year: payrollYear,
    payroll_sheet: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [importFileValues, setImportFileValues] = useState(payrollSheetlValues);
  const [detailValues, setDetailValues] = useState(initialValues);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false);
    dispatch(getAllEmployeesNames({}));
    sessionStorage.removeItem("filter_year");
  }, [dispatch]);

  const ClearFilter = () => {
    const today = new Date();

    // Set startDate to today
    setStartDate(today);

    // Set filterYear to the current year
    setFilterYear(today);
    dispatch(
      getPayroll({
        year: today.getFullYear(),
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
      })
    );
    setFilterEmp("");
    setFilterMonth("");
    setIsFilterApplied(false);
  };

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getPayroll({
          emp: filterEmp,
          month: filterMonth,
          year: filterYear.getFullYear(),
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
      setFilterChange(false);
    }
  };

  const rowChangeUseEffect = () => {
    if (payroll.length < count) {
      dispatch(
        getPayroll({
          emp: filterEmp,
          month: filterMonth,
          year: filterYear.getFullYear(),
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      payroll.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getPayroll({
          emp: filterEmp,
          month: filterMonth,
          year: filterYear.getFullYear(),
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (payroll.length < page * perPage &&
          (!payroll.length || payroll.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getPayroll({
            emp: filterEmp,
            month: filterMonth,
            year: filterYear.getFullYear(),
            page_size: perPage,
            page,
            rowChange,
            filterChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.employee_name}
        </div>
      ),
      selector: (row) => row.employee_name,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Salary</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.basic_salary.toFixed(3).replace(/\.?0*$/, "")}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Bonus</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.bonus.toFixed(3).replace(/\.?0*$/, "")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full flex gap-1 items-center text-slate-600">
          <div>Reimbursed</div>
          <IoTrendingUpOutline className="text-emerald-500" />
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.reimbursement.toFixed(3).replace(/\.?0*$/, "")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full flex gap-1 items-center text-slate-600">
          <div>Tax Deduction</div>
          <IoTrendingDownOutline className="text-red-500" />
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.tax_deduction.toFixed(3).replace(/\.?0*$/, "")}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">
          Travel Allowance
        </div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.travel_allowance.toFixed(3).replace(/\.?0*$/, "")}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Month</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {toTitleCase(row.month)}
        </div>
      ),
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Year</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.year}</div>
      ),
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <span
            className={`p-2 rounded-md text-slate-50 ${
              row.released ? "bg-emerald-600" : "bg-sky-600"
            }`}
          >
            {row.released ? <span>Released</span> : <span>Pending</span>}
          </span>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Details</div>,
      cell: (row) => (
        <div className="text-center w-full">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => {
              setDetailValues({ ...row });
              openDetailModal();
            }}
          >
            <IoEyeOutline className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => {
        return (
          <div className="flex justify-center gap-2 w-full">
            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => handleEdit(row)}
            >
              <IoPencil className="w-5 h-5 text-inherit" />
            </button>
            {enable ? (
              <button
                className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                onClick={() => handleSendEmail(row)}
              >
                <IoPaperPlaneOutline className="w-5 h-5 text-inherit" />
              </button>
            ) : (
              <div className="text-slate-50 font-semibold p-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                <RotatingLinesLoader />
              </div>
            )}

            <button
              className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
              onClick={() => {
                openDeleteModal();
                setDeleteId(row.id);
              }}
            >
              <IoTrashOutline className="w-5 h-5 text-inherit" />
            </button>
          </div>
        );
      },
    },
  ];

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "released") {
      const selectedValue = value === "true";
      setFormValues({ ...formValues, released: selectedValue });
    } else if (name === "reimbursement") {
      const selectedValue = value;
      setFormValues({
        ...formValues,
        reimbursement: selectedValue,
        config: { ...formValues.config, arrears: parseFloat(selectedValue) },
      });
    } else if (name === "travel_allowance") {
      const selectedValue = value;
      setFormValues({
        ...formValues,
        travel_allowance: selectedValue,
        config: { ...formValues.config, allowance: parseFloat(selectedValue) },
      });
    } else if (name === "tax_deduction") {
      const selectedValue = value;
      setFormValues({
        ...formValues,
        tax_deduction: selectedValue,
        config: {
          ...formValues.config,
          tax_deduction: parseFloat(selectedValue),
        },
      });
    } else if (name.startsWith("config.")) {
      const configName = name.split(".")[1];
      // const parsedValue = value === "" ? 0.0 : parseFloat(value);
      setFormValues({
        ...formValues,
        config: { ...formValues.config, [configName]: value },
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleDelete = () => {
    dispatch(
      deletePayroll({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Payroll");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Payroll");
    setDetailValues(initialValues);
    closeDetailModal();
    openEditModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { uidb64 } = formValues;
    if (
      formValues.uidb64 === "" ||
      formValues.uidb64 === null ||
      formValues.uidb64 === undefined
    ) {
      try {
        try {
          await payrollSchema.validate(formValues, { abortEarly: false });

          dispatch(
            addPayroll({
              formValues,
              toast,
              navigate,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to add employee", err);
      }
    } else {
      try {
        try {
          await payrollSchema.validate(formValues, { abortEarly: false });

          dispatch(
            editPayroll({
              uidb64,
              formValues,
              toast,
              closeEditModal,
              setEnable,
            })
          );
        } catch (validationErrors) {
          const errors = {};
          if (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
          setErrors(errors);
        }
      } catch (err) {
        console.error("Failed to update employee", err);
      }
    }
  };

  const filterData = () => {
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  const handleFileUploadChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "payroll_sheet") {
      const selectedFile = files[0];
      setImportFileValues({
        ...importFileValues,
        payroll_sheet: selectedFile,
      });
    } else {
      setImportFileValues({ ...importFileValues, [name]: value });
    }
  };

  const handleFileUploadSubmit = async (e) => {
    e.preventDefault();
    try {
      if (typeof importFileValues.payroll_sheet !== "object") {
        const errors = {
          payroll_sheet: "Please upload the file.",
        };
        setErrors({ ...errors });
        return;
      }

      try {
        await importPayrollSchema.validate(importFileValues, {
          abortEarly: false,
        });

        dispatch(
          importPayroll({
            formValues: { ...importFileValues },
            toast,
            navigate,
            setEnable,
          })
        );
      } catch (validationErrors) {
        const errors = {};

        if (validationErrors && validationErrors.inner) {
          validationErrors.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        }

        setErrors({ ...errors, payroll_sheet: "Please upload the file." });
      }
    } catch (err) {
      console.error("Failed to import payrolls", err);
    }
  };

  const handleSendEmail = async (row) => {
    try {
      dispatch(
        emailPayroll({
          formValues: { ...row },
          toast,
          setEnable,
          navigate,
        })
      );
    } catch (err) {
      toast.error("Failed to send email");
      console.error(("Failed to send email", err));
    }
  };

  const handleFileUploadClose = () => {
    closeImportModal();
    setImportFileValues(payrollSheetlValues);
  };

  return (
    <div>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Employee</label>
                  <select
                    value={formValues.employee}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="employee"
                  >
                    <option value="" disabled>
                      Select Employee
                    </option>
                    {employees
                      ? employees
                          .filter(
                            (employee) => employee.employee_status === "WORKING"
                          )
                          .map((emp, index) => (
                            <option key={index} value={emp.id}>
                              {emp.employee_name}
                            </option>
                          ))
                      : null}
                  </select>
                  {errors.employee && (
                    <p className="form-errors">{errors.employee}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Basic Salary
                  </label>
                  <input
                    value={formValues.basic_salary}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="basic_salary"
                  />
                  {errors.basic_salary && (
                    <p className="form-errors">{errors.basic_salary}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Project Bonus
                  </label>
                  <input
                    value={formValues.config?.project_bonus}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.project_bonus"
                  />
                  {errors.config?.project_bonus && (
                    <p className="form-errors">
                      {errors.config?.project_bonus}
                    </p>
                  )}
                </div>
                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Project Commission
                  </label>
                  <input
                    value={formValues.config?.project_commission}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.project_commission"
                  />
                  {errors.config?.project_commission && (
                    <p className="form-errors">
                      {errors.config?.project_commission}
                    </p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Late Sitting Bonus
                  </label>
                  <input
                    value={formValues.config?.late_sitting_bonus}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.late_sitting_bonus"
                  />
                  {errors.config?.late_sitting_bonus && (
                    <p className="form-errors">
                      {errors.config?.late_sitting_bonus}
                    </p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Total Bonus
                  </label>
                  <input
                    value={formValues.bonus}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="bonus"
                  />
                  {errors.bonus && (
                    <p className="form-errors">{errors.bonus}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Reimbursement
                  </label>
                  <input
                    value={formValues.reimbursement}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="reimbursement"
                  />
                  {errors.reimbursement && (
                    <p className="form-errors">{errors.reimbursement}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Increment</label>
                  <input
                    value={formValues.config?.increment}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.increment"
                  />
                  {errors.config?.increment && (
                    <p className="form-errors">{errors.config?.increment}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Overtime</label>
                  <input
                    value={formValues.config?.overtime}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.overtime"
                  />
                  {errors.config?.overtime && (
                    <p className="form-errors">{errors.config?.overtime}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Loan / Advance
                  </label>
                  <input
                    value={formValues.config?.loan_advance}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.loan_advance"
                  />
                  {errors.config?.loan_advance && (
                    <p className="form-errors">{errors.config?.loan_advance}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Tax Deduction
                  </label>
                  <input
                    value={formValues.tax_deduction}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="tax_deduction"
                  />
                  {errors.tax_deduction && (
                    <p className="form-errors">{errors.tax_deduction}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Total Deductions
                  </label>
                  <input
                    value={formValues.config?.deductions}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.deductions"
                  />
                  {errors.config?.deductions && (
                    <p className="form-errors">{errors.config?.deductions}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Travel Allowance
                  </label>
                  <input
                    value={formValues.travel_allowance}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="travel_allowance"
                  />
                  {errors.travel_allowance && (
                    <p className="form-errors">{errors.travel_allowance}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">
                    Total Salary
                  </label>
                  <input
                    value={formValues.config?.total_salary}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="number"
                    name="config.total_salary"
                  />
                  {errors.config?.total_salary && (
                    <p className="form-errors">{errors.config?.total_salary}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Month</label>
                  <select
                    value={formValues.month}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="month"
                  >
                    <option value="" disabled>
                      Select Month
                    </option>
                    <option value="JANUARY">January</option>
                    <option value="FEBRUARY">February</option>
                    <option value="MARCH">March</option>
                    <option value="APRIL">April</option>
                    <option value="MAY">May</option>
                    <option value="JUNE">June</option>
                    <option value="JULY">July</option>
                    <option value="AUGUST">August</option>
                    <option value="SEPTEMBER">September</option>
                    <option value="OCTUBER">October</option>
                    <option value="NOVEMBER">November</option>
                    <option value="DECEMBER">December</option>
                  </select>
                  {errors.month && (
                    <p className="form-errors">{errors.month}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Year</label>
                  <DatePicker
                    dateFormat="yyyy"
                    showYearPicker
                    required
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                      const updatedyear = date.getFullYear();
                      setFormValues({
                        ...formValues,
                        year: updatedyear,
                      });
                    }}
                  />
                  {errors.year && <p className="form-errors">{errors.year}</p>}
                </div>

                <div className="md:col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">Released</label>
                  <select
                    onChange={handleChange}
                    defaultValue={formValues.released ? "true" : "false"}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="released"
                  >
                    <option value={"false"}>Pending</option>
                    <option value={"true"}>Released</option>
                  </select>
                  {errors.released && (
                    <p className="form-errors">{errors.released}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex gap-4">
                  {enable ? (
                    <button
                      className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                      type="submit"
                    >
                      Save
                    </button>
                  ) : (
                    <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                      <RotatingLinesLoader />
                    </div>
                  )}
                  <button
                    type="button"
                    className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                    onClick={closeEditModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <ImportModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">Upload Payrolls</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={handleFileUploadClose}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleFileUploadSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Month</label>
                  <select
                    value={importFileValues.month}
                    onChange={handleFileUploadChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    name="month"
                  >
                    <option value="" disabled>
                      Select Month
                    </option>
                    <option value="JANUARY">January</option>
                    <option value="FEBRUARY">February</option>
                    <option value="MARCH">March</option>
                    <option value="APRIL">April</option>
                    <option value="MAY">May</option>
                    <option value="JUNE">June</option>
                    <option value="JULY">July</option>
                    <option value="AUGUST">August</option>
                    <option value="SEPTEMBER">September</option>
                    <option value="OCTUBER">October</option>
                    <option value="NOVEMBER">November</option>
                    <option value="DECEMBER">December</option>
                  </select>
                  {errors.month && (
                    <p className="form-errors">{errors.month}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Year</label>
                  <DatePicker
                    dateFormat="yyyy"
                    showYearPicker
                    required
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                      const updatedyear = date.getFullYear();
                      setImportFileValues({
                        ...importFileValues,
                        year: updatedyear,
                      });
                    }}
                  />
                  {errors.year && <p className="form-errors">{errors.year}</p>}
                </div>

                <div className="md:col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">
                    Upload Spreadsheet
                  </label>
                  <input
                    defaultValue={importFileValues.payroll_sheet}
                    onChange={handleFileUploadChange}
                    className="w-full px-2 py-1.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    name="payroll_sheet"
                  />
                  {errors.payroll_sheet && (
                    <p className="form-errors">{errors.payroll_sheet}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex gap-4">
                  {enable ? (
                    <button
                      className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                      type="submit"
                    >
                      Upload
                    </button>
                  ) : (
                    <div className="text-slate-50 font-semibold px-4 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                      <RotatingLinesLoader />
                    </div>
                  )}
                  <button
                    type="button"
                    className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                    onClick={handleFileUploadClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ImportModal>

      <DetailModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-emerald-500/90">
              {detailValues.employee_name}
            </h5>
            <div className="flex items-center justify-center gap-2">
              <IoPencil
                className="w-6 h-6 text-emerald-500 hover:text-emerald-400 transition-all duration-300 cursor-pointer"
                onClick={() => handleEdit(detailValues)}
              />
              <IoCloseOutline
                className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                onClick={closeDetailModal}
              />
            </div>
          </div>
          <div className="m-1">
            <div className="flex flex-col my-2 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
              <div className="mb-0 py-2 px-3 bg-gray-50 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                  Payroll Details
                </h5>
              </div>

              <div className="sm:p-4 p-3">
                <div className="flex md:gap-2 sm:gap-1 flex-col md:flex-row md:justify-around md:mt-3 max-md:items-center">
                  <div className="grid md:grid-cols-2 w-full gap-2">
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/2 text-slate-600">
                        Name:
                      </h5>
                      <h5 className="md:w-1/2 md:ml-0 ml-2 text-slate-500">
                        {detailValues.employee_name}
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/2 text-slate-600">
                        Status:
                      </h5>
                      <h5 className="md:w-1/2 md:ml-0 ml-2 text-slate-500">
                        <span
                          className={`p-2 rounded-md text-slate-50 ${
                            detailValues.released
                              ? "bg-emerald-600"
                              : "bg-sky-600"
                          }`}
                        >
                          {detailValues.released ? (
                            <span>Released</span>
                          ) : (
                            <span>Pending</span>
                          )}
                        </span>
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/2 text-slate-600">
                        Month:
                      </h5>
                      <h5 className="md:w-1/2 md:ml-0 ml-2 text-slate-500">
                        {toTitleCase(detailValues.month)}, {detailValues.year}
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/2 text-slate-600">
                        Net Salary:
                      </h5>
                      <h5 className="md:w-1/2 md:ml-0 ml-2 text-slate-500">
                        {detailValues.config?.total_salary?.toLocaleString()}{" "}
                        PKR
                      </h5>
                    </div>

                    <p className="font-bold md:col-span-2 text-slate-500 text-xl mt-4 md:ml-4 underline">
                      Salary Breakdown:
                    </p>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Basic Salary:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.basic_salary?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Allowance:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.travel_allowance?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Project Bonus:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.project_bonus?.toLocaleString()}{" "}
                        PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Project Commission:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.project_commission?.toLocaleString()}{" "}
                        PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Late Sitting Bonus:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.late_sitting_bonus?.toLocaleString()}{" "}
                        PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Total Bonus:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.bonus?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Reimbursed:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.reimbursement?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Increment:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.increment?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Overtime:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.overtime?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Loan / Advance:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.loan_advance?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Tax Deduction:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.tax_deduction?.toLocaleString()} PKR
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4 max-md:ml-4">
                      <h5 className="font-bold md:w-40 text-slate-600">
                        Total Deductions:
                      </h5>
                      <h5 className="md:ml-0 ml-2 text-slate-500">
                        {detailValues.config.deductions?.toLocaleString()} PKR
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
              <div className="flex gap-4">
                {enable ? (
                  <button
                    onClick={() => handleSendEmail(detailValues)}
                    className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                  >
                    Send Email
                  </button>
                ) : (
                  <div className="text-slate-50 font-semibold px-4 py-[9px] bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                    <RotatingLinesLoader />
                  </div>
                )}
                <button
                  onClick={() => {
                    openDeleteModal();
                    setDeleteId(detailValues.id);
                  }}
                  className="px-3 py-2 bg-red-500/70 hover:bg-red-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                >
                  Delete
                </button>

                <button
                  type="button"
                  className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                  onClick={closeDetailModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </DetailModal>

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
            <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
              Payrolls
            </h5>
            <div className="flex md:flex-row gap-2 md:items-center max-md:items-start flex-wrap">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Employees"
                  value={filterEmp}
                  onChange={(e) => setFilterEmp(e.target.value)}
                  className="rounded-lg p-2 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 w-40"
                />
              </div>
              <div>
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  value={filterMonth}
                  onChange={(e) => setFilterMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  <option value="JANUARY">January</option>
                  <option value="FEBRUARY">February</option>
                  <option value="MARCH">March</option>
                  <option value="APRIL">April</option>
                  <option value="MAY">May</option>
                  <option value="JUNE">June</option>
                  <option value="JULY">July</option>
                  <option value="AUGUST">August</option>
                  <option value="SEPTEMBER">September</option>
                  <option value="OCTUBER">October</option>
                  <option value="NOVEMBER">November</option>
                  <option value="DECEMBER">December</option>
                </select>
              </div>
              <div>
                <DatePicker
                  placeholderText="Select Year"
                  dateFormat="yyyy"
                  autoComplete="off"
                  showYearPicker
                  required
                  className="rounded-lg px-2 py-1.5 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 w-32"
                  maxDate={new Date()}
                  selected={filterYear}
                  onChange={(date) => setFilterYear(date)}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={() => filterData()}
                >
                  Apply Filter
                </button>
              </div>
              {isFilterApplied && (
                <div>
                  <button
                    type="button"
                    className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50 max-md:w-full"
                    onClick={() => ClearFilter()}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    Clear Filter
                  </button>
                </div>
              )}
              <div>
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={() => {
                    setImportFileValues(payrollSheetlValues);
                    openImportModal();
                  }}
                  style={{ marginLeft: "30px" }}
                >
                  Import Payroll
                </button>
              </div>
              <div>
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={handleNew}
                >
                  Add Payroll
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Pagination
              data={payroll}
              filterChange={filterChange}
              filterUseEffect={filterUseEffect}
              setRowChange={setRowChange}
              rowChangeUseEffect={rowChangeUseEffect}
              pageChangeUseEffect={pageChangeUseEffect}
              isLoading={payrollLoading}
              count={count}
              columns={columns}
              page={page}
              perPage={perPage}
              setPerPage={setPerPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payroll;
