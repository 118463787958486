import React, { useEffect, useState } from "react";
import { BarsLoader, RotatingLinesLoader } from "../../components/Loaders";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  getPolicies,
  addPolicy,
  editPolicy,
  deletePolicy,
  selectAllPolicies,
  selectPolicyLoading,
  selectPolicyCount,
} from "../../redux/features/policiesSlice";
import { IoTrashOutline, IoCloseOutline, IoEyeOutline } from "react-icons/io5";
import { EditModal, DeleteModal } from "../../components/Modals";
import { policySchema } from "../../validation-schemas/schemas";
import moment from "moment";
import Pagination from "../../components/Pagination";

const Documents = () => {
  const {
    portal,
    openDeleteModal,
    closeDeleteModal,
    enable,
    setEnable,
    openEditModal,
    closeEditModal,
  } = useStateContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    file_name: "",
    policy_file: "",
    uidb64: "",
  };

  const policies = useSelector(selectAllPolicies);
  const policiesLoader = useSelector(selectPolicyLoading);
  const count = useSelector(selectPolicyCount);
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Document");
  const [deleteId, setDeleteId] = useState("");

  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [rowChange, setRowChange] = useState(false);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const rowChangeUseEffect = () => {
    if (policies.length < count) {
      dispatch(
        getPolicies({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
        })
      );
    }
    setRowChange(false);
  };

  const pageChangeUseEffect = () => {
    if (
      policies.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getPolicies({
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange: true,
        })
      );
    } else {
      if (
        (policies.length < page * perPage &&
          (!policies.length || policies.length < count) &&
          count !== 0) ||
        isInitialLoad
      ) {
        dispatch(
          getPolicies({
            toast,
            navigate,
            page_size: perPage,
            page,
            rowChange,
            isInitialLoad,
          })
        );
      }
    }
  };

  const columns = [
    {
      name: <div className="text-center w-full text-slate-600">Name</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.file_name}</div>
      ),
      selector: (row) => row.file_name,
      sortable: true,
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">Last Updated</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {moment(row?.modified).format("MMM. DD, YYYY")}
        </div>
      ),
      selector: (row) => row?.modified,
      sortable: true,
    },
    {
      name: <div className="text-center w-full text-slate-600">Updated By</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row?.modified_by?.split(/\s(?=\S+$)/)[0]}
        </div>
      ),
    },
    {
      name: (
        <div className="text-center w-full text-slate-600">View Policy</div>
      ),
      cell: (row) => (
        <div className="text-center w-full grid place-content-center">
          <a
            href={row.policy_file}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer w-10 py-2 flex items-center justify-center bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
          >
            <IoEyeOutline className="w-5 h-5 text-inherit text-slate-50" />
          </a>
        </div>
      ),
    },
    ...(portal !== "employee"
      ? [
          {
            name: (
              <div className="text-center w-full text-slate-600">Action</div>
            ),
            cell: (row) => {
              return (
                <div className="w-full flex justify-center gap-2">
                  <button
                    className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    onClick={() => {
                      setDeleteId(row.id);
                      openDeleteModal();
                    }}
                  >
                    <IoTrashOutline className="w-5 h-5 text-inherit" />
                  </button>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const handleNew = () => {
    setFormValues(initialValues);
    setErrors({});
    setHeading("Add Document");
    openEditModal();
  };

  const handleEdit = (row) => {
    setFormValues({
      ...row,
      uidb64: row.id,
    });
    setErrors({});
    setHeading("Update Document");
    openEditModal();
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "policy_file") {
      const selectedFile = files[0];
      setFormValues({
        ...formValues,
        policy_file: selectedFile,
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        formValues.uidb64 === "" ||
        formValues.uidb64 === null ||
        formValues.uidb64 === undefined
      ) {
        if (typeof formValues.policy_file !== "object") {
          const errors = {
            policy_file: "Please upload the policy file.",
          };
          setErrors({ ...errors });
          return;
        }

        try {
          await policySchema.validate(formValues, {
            abortEarly: false,
          });

          dispatch(
            addPolicy({
              formValues,
              toast,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};

          if (validationErrors && validationErrors.inner) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }

          setErrors({ ...errors, policy_file: "Please upload the file." });
        }
      } else {
        if (typeof formValues.policy_file !== "object") {
          const errors = {
            policy_file: "Please upload the policy file.",
          };
          setErrors({ ...errors });
          return;
        }

        try {
          await policySchema.validate(formValues, {
            abortEarly: false,
          });

          dispatch(
            editPolicy({
              formValues,
              toast,
              setEnable,
              closeEditModal,
            })
          );
        } catch (validationErrors) {
          const errors = {};

          if (validationErrors && validationErrors.inner) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }

          setErrors({ ...errors, policy_file: "Please upload the file." });
        }
      }
    } catch (err) {
      console.error("Failed to add policy", err);
    }
  };

  const handleDelete = () => {
    dispatch(
      deletePolicy({ uidb64: deleteId, toast, closeDeleteModal, setEnable })
    );
  };

  return (
    <>
      <DeleteModal handleDelete={handleDelete} />

      {policiesLoader ? (
        <div className="dashboard-min-height grid place-content-center">
          <BarsLoader />
        </div>
      ) : (
        <div>
          <EditModal>
            <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
                <IoCloseOutline
                  className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={() => closeEditModal()}
                />
              </div>
              <div className="m-1">
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        File Name
                      </label>
                      <input
                        value={formValues.file_name}
                        onChange={handleChange}
                        className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        name="file_name"
                      />

                      {errors.file_name && (
                        <p className="form-errors">{errors.file_name}</p>
                      )}
                    </div>

                    <div>
                      <label className="ml-1 mb-1 text-slate-600">
                        Upload Document
                      </label>
                      <input
                        defaultValue={formValues.policy_file}
                        onChange={handleChange}
                        className="w-full px-2 py-1.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md"
                        type="file"
                        accept="application/pdf"
                        name="policy_file"
                      />
                      {errors.policy_file && (
                        <p className="form-errors">{errors.policy_file}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
                    <div className="flex gap-4">
                      {enable ? (
                        <button
                          className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer  transition-all duration-300"
                          type="submit"
                        >
                          Upload
                        </button>
                      ) : (
                        <div className="text-slate-50 font-semibold px-4 py-[9px] bg-emerald-500/70 hover:bg-emerald-500/90 rounded-md">
                          <RotatingLinesLoader />
                        </div>
                      )}
                      <button
                        type="button"
                        className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        onClick={() => closeEditModal()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </EditModal>

          <div className="flex flex-col justify-between lg:flex-row items-center mx-3 rounded-md">
            <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full rounded-md">
              <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between rounded-t-md">
                <h5
                  className={`md:mb-0 max-md:mb-2 font-bold text-slate-600 ${
                    portal !== "employee" ? "" : "py-2"
                  }`}
                >
                  Documents
                </h5>
                {portal !== "employee" ? (
                  <button
                    onClick={handleNew}
                    className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  >
                    Add Document
                  </button>
                ) : null}
              </div>
              <div className="card-body">
                <Pagination
                  data={policies}
                  setRowChange={setRowChange}
                  rowChangeUseEffect={rowChangeUseEffect}
                  pageChangeUseEffect={pageChangeUseEffect}
                  isLoading={policiesLoader}
                  count={count}
                  columns={columns}
                  page={page}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Documents;
