import { Bars, RotatingLines } from 'react-loader-spinner';

export const BarsLoader = () => {
  return (
    <div className="grid place-content-center loader-min-height">
      <Bars
        height="80"
        width="80"
        color="#10b98180"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export const RotatingLinesLoader = () => {
  return (
    <RotatingLines
      strokeColor="white"
      strokeWidth="6"
      animationDuration="0.75"
      width="25"
      visible={true}
    />
  );
};
